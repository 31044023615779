import { BestPracticePostDownloadDto, ControllerHelper, PostController, PostDownloadDto } from 'collaboration-service';
import ContentPost from 'components/Posts/ContentPost/ContentPost';
import PorscheMomentPostMobile from 'components/Posts/PorscheMomentPost/PorscheMomentPostMobile';
import PorscheMomentPostMobileLoader from 'components/Posts/PorscheMomentPost/PorscheMomentPostMobileLoader';
import QuizPost from 'components/Posts/QuizPost/QuizPost';
import SlideshowPost from 'components/Posts/SlideshowPost/SlideshowPost';
import StandardPost from 'components/Posts/StandardPost';
import TubePost from 'components/Posts/TubePost/TubePost';
import { belowBreakpointOrEqual, fromThemeCreator, styled } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { Actions, WorkingActions } from 'services/ApplicationState/Actions';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { shallowCompare } from 'services/Helpers';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { useImgI18N } from 'services/ImgI18N';
import { changeLastGroup } from 'services/StoreDependantHelpers';
import useOnScreenDiv from 'services/useOnScreenDiv';
import { ThemeContext } from 'styled-components';
import BestPracticePost from 'views/BestPractice/BestPracticePost';
import NewsPost from 'views/News/NewsPost';
import { ApplicationState, useAppDispatch, useAppSelector } from '../../services/ApplicationState/ApplicationState';
import ScrollKeeper from '../../services/Helpers/ScrollKeeper';
import history from '../../services/History';
import { createFetchPostFilter } from './FeedFilter';
import { FeedTheme } from './FeedMain';

const mapper = (state: ApplicationState) => ({
    trending: state.feedState.trending,
    userGroups: state.userGroups,
    user: state.user,
    posts: state.posts,
    token: state.postsToken,
    currentGroup: state.currentGroup,
    feedSearchProps: state.feedState.searchProps,
});
export interface FeedProps {
}
const selectFromTheme = fromThemeCreator<FeedTheme>("feedTheme");

const PostContainerDivOuter = styled.div`
    margin: auto;
    width: 100%;
    padding: 15px 0;
    position: relative;
    margin-bottom: -1px;
`;
const PostContainerDivOuterBg = styled.div`
    position: absolute;
    inset: 1px;
    background: linear-gradient(90deg, #fff 10%, rgb(240, 239, 247), #fff 90%);
`;

const PostContainerDiv = styled.div`
    margin: auto;
    width: 100%;
    max-width: ${props => selectFromTheme(props, t => t.post.maxWidth, t => "640px")};
    min-height: 100px;
    position: relative;
`;

export const RenderPost = (p: { post: PostDownloadDto, postChanged: (post: PostDownloadDto) => void, postStyle?: React.CSSProperties }) => {
    const { post, postChanged, postStyle } = p;
    const renderedPost = React.useMemo(() => {
        const type = post?.type;
        switch (type) {
            case "ContentPost":
                return <ContentPost post={post} postChanged={postChanged} />;
            case "TubePost":
                return <TubePost post={post} postChanged={postChanged} />;
            case "CommunityFeedPost":
                return <PorscheMomentPostMobile post={post} postChanged={postChanged} />;
            case "PorscheMomentPost":
                return <PorscheMomentPostMobile post={post} postChanged={postChanged} />;
            case "SlideShow":
                return <SlideshowPost post={post} postChanged={postChanged} />;
            case "Quiz":
                return <QuizPost post={post} postChanged={postChanged} />;
            case "NewsPost":
                return <NewsPost post={post} postChanged={postChanged} />;
            case "BestPracticePost":
                return <BestPracticePost post={post as BestPracticePostDownloadDto} />;
            default:
                return <StandardPost post={post} postChanged={postChanged} />
        }

    }, [post, postChanged]);
    return (
        <PostContainerDivOuter key={post.id} className="PostContainerDivOuter" style={postStyle}>
            <PostContainerDivOuterBg />
            <PostContainerDiv>
                {renderedPost}
            </PostContainerDiv>
        </PostContainerDivOuter>
    );
}

const Feed = (p: FeedProps) => {

    const { user, userGroups, currentGroup, token, trending, feedSearchProps, posts } = useAppSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [scrollHelper,] = React.useState<ScrollHelper>(new ScrollHelper({ behavior: 'instant' }));
    const [scroll,] = React.useState<ScrollKeeper>(ScrollKeeper.get(history.location));
    const [feedStartIndex, setFeedStartIndex] = React.useState<number>(0);
    //const browser = useThemePart(x => x.browser);

    // React.useEffect(() => console.log("Feed.tsx::66 **Changed** => scrollHelper", scrollHelper), [scrollHelper]);
    // React.useEffect(() => console.log("Feed.tsx::67 **Changed** => scroll", scroll), [scroll]);
    // React.useEffect(() => console.log("Feed.tsx::68 **Changed** => token"), [token]);
    // React.useEffect(() => console.log("Feed.tsx::69 **Changed** => user", user), [user]);
    // React.useEffect(() => console.log("Feed.tsx::70 **Changed** => userGroups", userGroups), [userGroups]);
    // React.useEffect(() => console.log("Feed.tsx::71 **Changed** => currentGroup", currentGroup), [currentGroup]);
    // React.useEffect(() => console.log("Feed.tsx::72 **Changed** => trending", trending), [trending]);
    // React.useEffect(() => console.log("Feed.tsx::73 **Changed** => feedSearchProps", JSON.stringify(feedSearchProps)), [feedSearchProps]);
    // React.useEffect(() => console.log("Feed.tsx::74 **Changed** => posts", posts), [posts]);
    // React.useEffect(() => console.log("Feed.tsx::76 **Changed** => dispatch", dispatch), [dispatch]);

    //const isIOS = MobileHelper.getInstance().iOSClient || (browser === "ios" || browser === "crios" || browser === "fxios");
    const isIOS = true;

    const scrollTo = React.useMemo(() => (id: string) => {
        scrollHelper.scrollTo(id, 0);
    }, [scrollHelper]);

    const stopAllMedia = () => {
        window.dispatchEvent(new Event('stopAllAudio'));
        const mediaElements = document.querySelectorAll<HTMLMediaElement>('video, audio');
        mediaElements.forEach((mediaElement) => {
            mediaElement.pause();
        });
    };

    const gotoTopFirst = React.useCallback(() => {
        stopAllMedia();
        setFeedStartIndex(0);
        scrollTo('top');
    }, [scrollTo]);


    const pl = posts?.length ?? 0;

    const loadMore = React.useMemo(() => () => {
        stopAllMedia();
        // console.log("loading more...");
        if (isIOS) {
            //dispatch(PluginActions.setPosts(undefined, token));
            scrollTo("top");
        }
        if (feedStartIndex + 12 < pl)
            setFeedStartIndex(i => i + 12);
        else
            dispatch(WorkingActions.FEED.loadPosts(false, feedSearchProps, () => setFeedStartIndex(i => i + 1)));
    }, [dispatch, scrollTo, isIOS, feedSearchProps, feedStartIndex, pl]);

    const OnScreenDiv = useOnScreenDiv({ work: loadMore });

    React.useEffect(() => {
        const preventDefaultZoom = (e: TouchEvent) => {
            if (e.touches.length > 1) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchmove', preventDefaultZoom, { passive: false });

        return () => {
            document.removeEventListener('touchmove', preventDefaultZoom);
        };
    }, []);


    React.useEffect(() => {
        dispatch(PluginActions.batchActions([
            Actions.setScrollTo(scrollTo),
            Actions.setAction(gotoTopFirst),
            PluginActions.setPosts(undefined, undefined)
        ]));
        // MUST BE CORRECTED TO FILTERED GROUPS!!!
        if (currentGroup?.groupType !== "COMMUNITY" && currentGroup?.groupType !== "EXCITE") {
            const eg = _.find(userGroups, g => g.groupType === "EXCITE");
            const cg = _.find(userGroups, g => g.groupType === "COMMUNITY");
            if (eg)
                changeLastGroup(eg);
            else if (cg)
                changeLastGroup(cg);
        }
        return () => {
            if (scroll)
                scroll.savePosition();
        }
    }, [dispatch, userGroups, currentGroup, scroll, scrollHelper, scrollTo, gotoTopFirst]);

    React.useEffect(() => {
        const l = async () => {
            const filter = createFetchPostFilter(user, undefined, undefined, undefined, undefined, undefined);
            const res = await ControllerHelper.singleCall({ text: "", filter }, PostController.SearchPostText3); //
            dispatch(Actions.setFeedFacets(res.facets));
        };
        l();
    }, [user, dispatch]);

    React.useEffect(() => {
        dispatch(WorkingActions.FEED.loadPosts(true, undefined, () => setFeedStartIndex(0)));
    }, [dispatch, user, trending, feedSearchProps]);

    //React.useEffect(() => console.log("Feed.tsx::190 **Changed** => feedSearchProps", JSON.stringify(feedSearchProps)), [feedSearchProps]);

    const updatePost = React.useMemo(() => (post: PostDownloadDto) => {
        dispatch(Actions.updatePost(post));
    }, [dispatch]);

    const useSnapScrolling = false;
    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "tablet");

    const divStyle: React.CSSProperties = useSnapScrolling ? {
        width: "100%",
        height: isMobile ? "calc(100% + 31px)" : "100%",
        overflowY: "auto",
        overflowX: "hidden",
        scrollBehavior: "smooth",
        scrollSnapType: isMobile ? "y proximity" : "none",
        marginTop: isMobile ? "-20px" : 0,
        position: "relative"
    } : {
        position: "relative"
    };
    const postStyle: React.CSSProperties = useSnapScrolling ? {
        scrollSnapAlign: isMobile ? "start" : "none",
        height: "auto"
    } : {};


    React.useEffect(() => {
        setFeedStartIndex(i => pl < i ? 0 : i);
    }, [pl]);

    const curPosts = React.useMemo(() => {
        if (!isIOS)
            return posts;
        // const toRet: PostDownloadDto[] = [];
        // for (let i = 0; i < 12; i++) {
        //     const toAdd = (posts ?? [])[i + feedStartIndex];
        //     if (toAdd)
        //         toRet.push(toAdd);
        // }
        const toRet = posts; //[];
        // if (feedStartIndex === 252) {
        //     return toRet.slice(9, 11);
        // }


        return toRet;
    }, [posts, isIOS])

    return (
        <div style={divStyle}>
            <div ref={scrollHelper.getRef("top")} />
            <div>{feedStartIndex}</div>

            {((token && pl > 0) || (feedStartIndex + 12 < pl)) &&

                <PorscheMomentPostMobileLoader onClick={isIOS ? loadMore : undefined} rotate />
            }

            {isIOS && feedStartIndex > 0 &&
                <PostContainerDivOuter className="PostContainerDivOuter" style={{ ...postStyle, paddingBottom: 20 }} key={`top-loader-${posts?.length}`}>
                    <PostContainerDivOuterBg />
                    <PostContainerDiv>
                        <PorscheMomentPostMobileLoader onClick={() => setFeedStartIndex(i => Math.max(0, i - 12))} infoText={t('click to load previous posts')} />
                    </PostContainerDiv>
                </PostContainerDivOuter>
            }
            {posts?.length === 0 ?
                <PostContainerDivOuter style={{ ...postStyle, height: "100%" }}>
                    <PostContainerDivOuterBg />
                    <PostContainerDiv>
                        <PorscheMomentPostMobileLoader infoText={t("no posts found")} icon='exclamation circle' />
                    </PostContainerDiv>
                </PostContainerDivOuter>
                :
                _.map(curPosts, (p, i) => {
                    return <RenderPost key={p.id} post={p} postChanged={updatePost} postStyle={postStyle} />
                })}

            {((token && pl > 0) || (feedStartIndex + 12 < pl)) &&
                <PostContainerDivOuter className="PostContainerDivOuter" style={{ ...postStyle, paddingBottom: 100 }} key={`loader-${posts?.length}`}>
                    <PostContainerDivOuterBg />
                    <PostContainerDiv>
                        {isIOS ? undefined : OnScreenDiv}
                        <PorscheMomentPostMobileLoader onClick={isIOS ? loadMore : undefined} rotate />
                    </PostContainerDiv>
                </PostContainerDivOuter>
            }
        </div >
    );
}
export default Feed;

import { ControllerHelper, PowerBiController, PowerBiObjectDownloadDto } from 'collaboration-service';
import InfoBox from 'components/InfoBox/InfoBox';
import TabsBar from 'components/TabsBar/TabsBar';
import { Button, getColor, Loader, styled } from 'imaginarity-react-ui';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import * as React from 'react';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';

const OuterContainer = styled.div`
    height: calc(100vh - 110px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 30px;
    border: 1px solid ${p => getColor(p, "@middleLightGrey")};
    border-top: none;
`;

export type MyHomeReportTab = "GLP" | "CMS";
export interface MyHomeStartGLPRProps {
}

const MyHomeStartGLPR = (p: MyHomeStartGLPRProps) => {
    const [selectedTab, setSelectedTab] = React.useState<MyHomeReportTab>("GLP");
    const [loading, setLoading] = React.useState<boolean>(false);

    const [report, setReport] = React.useState<PowerBiObjectDownloadDto>();

    const filterRetail = [{
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "GV_PPX_EXPORT_MODUL",
            column: "Org_Name"
        },
        filterType: 1,
        operator: "In",
        values: [report?.permissionValue]
    }];
    const filterMarket = [{
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "GV_PPX_EXPORT_MODUL",
            column: "Market"
        },
        filterType: 1,
        operator: "In",
        values: [report?.permissionValue]
    }];


    const tabsSelect = React.useMemo(() => (idx: number) => {
        if (idx === 0)
            setSelectedTab("GLP");
        else if (idx === 1)
            setSelectedTab("CMS");
    }, []);

    React.useEffect(() => {
        setLoading(true);
        const l = async () => {
            const poBiReport: PowerBiObjectDownloadDto = await ControllerHelper.singleCall({ workspace: selectedTab }, PowerBiController.GetReport, true);
            setReport(poBiReport);
            setLoading(false);
        };
        l();
    }, [selectedTab]);

    const pBiFilter = (report?.permissionLevel === "Market" && report?.permissionValue !== null) ? filterMarket : report?.permissionLevel === "Retailer" ? filterRetail : [];

    return (
        <div>
            <Loader active={loading} infoText='loading report data' size='small' light />
            <TabsBar
                input={[
                    {
                        title: "GLP Reporting",
                        icon: "file",
                        active: selectedTab === "GLP",
                        hidden: false
                    },
                    {
                        title: "CMS Reporting",
                        icon: "cms admin",
                        active: selectedTab === "CMS",
                        hidden: false
                    }
                ]}
                onChange={tabsSelect}
                additionalContent={<Button kind="cancelButton" onClick={gotoNamedRouteMethod("myhome")} floated='right' icon='chevron left' />}
                verticalSlider
                activeBold
            // shadowed
            />
            {report ?
                <OuterContainer >
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                            id: report.objectId,
                            embedUrl: report.embedUrl,
                            accessToken: report.token,
                            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                            filters: pBiFilter,

                            settings: {
                                filterPaneEnabled: false,
                                navContentPaneEnabled: false,
                            }
                        }}

                        cssClassName={
                            "report-style-class"
                        }

                        eventHandlers={
                            new Map([
                                ['loaded', function () { console.log('Report loaded'); }],
                                ['rendered', function () { console.log('Report rendered'); }],
                                ['error', function (event: any) { console.log(event.detail); }],
                                ['visualClicked', () => console.log('visual clicked')],
                                ['pageChanged', (event) => console.log(event)],
                            ])
                        }

                        getEmbeddedComponent={(embeddedReport) => {
                            //this.report = embeddedReport as Report;
                        }}
                    />
                </OuterContainer>
                :
                <div style={{ marginTop: 20 }}>
                    <InfoBox type='alert' content='no data found' icon='exclamation circle' />
                </div>
            }
        </div>
    );

}
export default MyHomeStartGLPR;

import { WikiSectionDownloadDto } from 'collaboration-service';
import CopyToClipboard from 'components/CopyToClipboard/CopyToClipboard';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import WikiEditTable from 'components/Wiki/WikiEditTable';
import { AutoGrid, AutoGridColumn, Button, Icon, Image } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch } from 'services/ApplicationState/ApplicationState';
import { getLink, searchHtmlAndMark, searchTableAndMark, shallowCompare } from 'services/Helpers';
import { getMediaLink } from 'services/Helpers/MediaHelpers';
import { createNamedRoute } from 'services/Helpers/RoutingHelper';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { getTranslated, getTranslatedExact } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { useBelowBreakpointOrEqual, useThemePart } from 'services/useAppTheme';
import { setVideoDefaultTrackByLng } from 'views/Tube/TubeContent';
import WikiHotSpot from './WikiHotSpot';
import { WikiSC as T } from './WikiSC';
import { wikiTheme } from './WikiTheme';


const elementResizeDetectorMaker = require("element-resize-detector");
const erdUltraFast = elementResizeDetectorMaker({
    strategy: "scroll" //<- For ultra performance.
});

const mapper = (state: ApplicationState) => ({
    search: state.wikiState.search,
    contentLanguage: state.contentLanguage,
    masterLayoutRootExtension: state.masterLayoutRootExtension,
    routeParams: state.params,
});

export interface WikiArticleSectionProps {
    section: WikiSectionDownloadDto;
    scrollHelper?: ScrollHelper;
    onIds?: (ids?: string[]) => void;
    addRefs: (name: string, ids?: string[], threshold?: number | number[]) => (r: HTMLDivElement | null) => void;
}

export interface WikiArticleSectionState {
    open: boolean;
    width: number;
}

const WikiArticleSection = (p: WikiArticleSectionProps) => {
    const [open, setOpen] = React.useState<boolean>(true);
    const [width, setWidth] = React.useState<number>(0);
    const { section: s, scrollHelper, onIds, addRefs } = p;
    const dispatch = useAppDispatch();
    const { contentLanguage, routeParams, search } = useSelector(mapper, shallowCompare);
    const { t, currentLanguage } = useImgI18N("wiki");
    const vidRef = React.useRef<HTMLVideoElement | null>(null);

    const secTitle = getTranslated(s.title, contentLanguage).text;
    const minimize = wikiTheme.articleTheme.minimizeIcon ?? "minimize";
    const maximize = wikiTheme.articleTheme.maximizeIcon ?? "maximize";
    const articleId = routeParams?.id as string;
    const routeSecId = routeParams?.secId as string;
    const routeColId = routeParams?.colId as string;

    React.useEffect(() => {
        if (search === undefined && onIds)
            onIds(undefined);
    }, [search, onIds]);

    const onFindings = React.useMemo(() => (id: string, ids: string[]) => {
        if (ids.length !== 0) {
            if (onIds)
                onIds(ids);
        }
    }, [onIds]);

    const setImageModal = React.useMemo(() => (modal: JSX.Element) => {
        dispatch(Actions.setMasterLayoutExtension(() => modal));
    }, [dispatch]);

    const removeImageModal = React.useMemo(() => () => {
        dispatch(Actions.setMasterLayoutExtension());
    }, [dispatch]);

    const setRef = React.useMemo(() => (ref?: any) => {
        const div = ref as HTMLDivElement;
        if (div) {
            erdUltraFast.listenTo({}, div, (ele: HTMLDivElement) => {
                setWidth(w => Math.floor(ele?.offsetWidth ?? 0));
            })
        }
    }, []);


    const isMobile = useBelowBreakpointOrEqual("mobile");
    const { contentWidth, missingAudioSvg, missingImageSvg, emptyVideoThumbnail, emptyImageUrl, missingVideoSvg } = useThemePart(s => ({
        contentWidth: s.content.width,
        missingAudioSvg: s.missingAudioSvg,
        missingImageSvg: s.missingImageSvg,
        missingVideoSvg: s.missingVideoSvg,
        emptyVideoThumbnail: s.emptyVideoThumbnail,
        emptyImageUrl: s.emptyImageUrl,
    }));

    const rs = vidRef.current?.readyState ?? 0;
    const lng = contentLanguage ?? currentLanguage;

    React.useEffect(() => {
        if (rs === 4)
            setVideoDefaultTrackByLng(vidRef.current, lng);
    }, [vidRef, rs, lng]);

    const sectionId = s.id;
    const urlSec = articleId ? window.location.origin + "/#" + createNamedRoute("wiki_article", { id: articleId, secId: sectionId }) : undefined;
    const fromClipboardLink = <T.PulsateIcon>
        <Icon name="chevron right double" style={{ float: "left", marginRight: 5, marginLeft: -3 }} marginTop={5} />
    </T.PulsateIcon>;
    const isLinkedSec = routeSecId === sectionId && (routeColId === undefined || routeColId === "");

    const getStartTag = React.useMemo(() => (id: string) => {
        return `<b id="#searchResult${id}-{{pos}}" class="searchFinding">` +
            `<b  style="position: absolute; top: -60px; left: 0px" id="#searchResultAnchor${id}-{{pos}}"></b>`;
    }, []);

    React.useEffect(() => {
        _.forEach(s.columns, c => {
            let table = getTranslated(c.tableContent, contentLanguage);
            let text = getTranslated(c.textContent, contentLanguage).text;
            if (search) {
                const starttag = getStartTag(c.id);
                const endtag = `</b>`;

                let findings = 0;
                const { count } = searchHtmlAndMark(text, search, starttag, endtag);
                findings += count;

                if (c.columnType === "Table") {
                    const { currentCount } = searchTableAndMark(table, search, starttag, endtag);
                    findings += currentCount;
                }
                onFindings(c.id, _.times(findings, x => `${c.id}-${x}`));
            }
            else
                onFindings(c.id, []);
        });
    }, [s, contentLanguage, search, onFindings, getStartTag]);

    // const { visible: sectionOnScreen, setRef: setSectionRef } = useOnScreen(0.1);
    // React.useEffect(() => {
    //     elementOnScreen(sectionOnScreen, sectionId);
    // }, [sectionOnScreen, sectionId, elementOnScreen]);

    return (
        <div ref={addRefs("section", [sectionId], 0.1)}>
            <T.SectionTitle
                isLinkedSec={isLinkedSec ?? false}
                open={open}
                lang={contentLanguage}
            >
                <Button
                    kind="transparentButton"
                    icon={open ? minimize : maximize}
                    onClick={() => setOpen(!open)}
                    floated="right"
                />
                {!open &&
                    <Icon name="eye close" color="rgba(0,0,0,0.3)" size="20px" style={{ float: "left", marginRight: 10, marginTop: 5, marginLeft: 10 }} />
                }
                {!secTitle || secTitle === "" ? <span>&nbsp;</span> :
                    <>
                        {isLinkedSec && fromClipboardLink}
                        {secTitle}
                        {urlSec && open &&
                            <CopyToClipboard
                                type='icon'
                                text={urlSec}
                                copyHint={t("copy link to clipboard")}
                                copiedHint={t("link copied to clipboard")}
                            />
                        }
                    </>
                }

            </T.SectionTitle>
            {open &&
                <>
                    {(s.mode === "Grid" || s.mode === "Swipe") &&
                        <AutoGrid columns={isMobile ? 1 : s.columnCount} gap={10}>
                            {_.map(s.columns, (c, i) => {
                                const colTitle = getTranslated(c.title, contentLanguage).text;
                                const colId = c.id;
                                const media = getTranslated(c.mediaContent, contentLanguage);
                                const isOverlayImage = c.mediaOverlay.length > 0;
                                const overlayData = getTranslatedExact(c.mediaOverlay, contentLanguage);

                                let table = getTranslated(c.tableContent, contentLanguage);
                                let text = getTranslated(c.textContent, contentLanguage).text;
                                if (search) {
                                    const starttag = getStartTag(c.id);
                                    const endtag = `</b>`;

                                    const { html } = searchHtmlAndMark(text, search, starttag, endtag);
                                    text = html;

                                    if (c.columnType === "Table") {
                                        const { tableNew } = searchTableAndMark(table, search, starttag, endtag);
                                        table = tableNew;
                                    }
                                }

                                let imgWidth = media?.width;
                                let imgHeight = media?.height;

                                if (!imgWidth || !imgHeight) {
                                    imgWidth = 400;
                                    imgHeight = 280;
                                }
                                const aspect = imgWidth / imgHeight;
                                const uri = getLink(media?.links, "self")?.uri;
                                const urlCol = articleId ? window.location.origin + "/#" + createNamedRoute("wiki_article", { id: articleId, secId: sectionId, colId: colId }) as string : "";
                                const isLinkedCol = routeColId === colId;
                                const w = media.mediaType === "image/svg+xml" ? undefined : (Math.min(width, media?.width ?? width));

                                return (
                                    <AutoGridColumn span={c.columnWidth} key={i} zIndex={0} divRef={addRefs("column", [sectionId, c.id], 0.1)}>
                                        <div style={{ position: "relative", width: 0, height: 0 }}>
                                            <div style={{ position: "absolute", top: -60, left: 0 }} ref={scrollHelper?.getRef(c.id)} />
                                        </div>
                                        <T.ColumnTitle
                                            isLinkedCol={isLinkedCol ?? false}
                                            style={{ lineHeight: (colTitle && colTitle !== "" && urlCol) ? "40px" : "1.6rem" }}
                                        >
                                            {isLinkedCol && fromClipboardLink}
                                            {colTitle}
                                            {(colTitle && colTitle !== "" && urlCol) &&
                                                <CopyToClipboard
                                                    text={urlCol}
                                                    type='icon'
                                                    copyHint={t("copy link to clipboard")}
                                                    copiedHint={t("link copied to clipboard")}
                                                />
                                            }
                                        </T.ColumnTitle>
                                        {c.columnType === "Text" &&
                                            <T.ColumnContent lang={contentLanguage}>
                                                <SafeHTML html={text} />
                                            </T.ColumnContent>
                                        }
                                        {c.columnType === "Table" &&
                                            <T.ColumnContent lang={contentLanguage} style={{ maxWidth: (contentWidth / (isMobile ? 1 : c.columnWidth > 1 ? 1 : s.columnCount) - (isMobile ? 40 : 10)) }}>
                                                <WikiEditTable
                                                    noEdit
                                                    table={table}
                                                    onChange={() => { }}
                                                />
                                            </T.ColumnContent>
                                        }
                                        {c.columnType === "Image" &&
                                            <T.ColumnContentImage imgWidth={media?.width} ref={setRef} lang={contentLanguage}>
                                                {isOverlayImage && overlayData ?
                                                    <T.HotSpotContainer
                                                        aspect={aspect}
                                                        width={w}
                                                    >
                                                        <T.HotSpotContainerImage >
                                                            <Image src={uri ?? emptyImageUrl} mediaType={media.mediaType} width={media.mediaType === "image/svg+xml" ? "100%" : undefined} />
                                                        </T.HotSpotContainerImage>
                                                        {_.map(overlayData.elements, (e, i) => {
                                                            const delay = (i + 1) * 0.5;
                                                            return (
                                                                <WikiHotSpot
                                                                    key={i}
                                                                    element={e}
                                                                    mediaOverlay={c.mediaOverlay}
                                                                    index={i}
                                                                    delay={delay}
                                                                    imgWidth={imgWidth ?? 400}
                                                                    imgHeight={imgHeight ?? 280}
                                                                    width={width}
                                                                    offsetY={0}
                                                                />
                                                            );
                                                        })}

                                                    </T.HotSpotContainer>
                                                    :
                                                    <Image
                                                        src={uri ?? missingImageSvg}
                                                        onClickReturnModal={setImageModal}
                                                        onRemoveModal={removeImageModal}
                                                        mediaType={media.mediaType}
                                                    />
                                                }
                                            </T.ColumnContentImage>
                                        }
                                        {c.columnType === "Video" &&
                                            <T.ColumnContent lang={contentLanguage}>
                                                {uri ?
                                                    <video
                                                        poster={getMediaLink(c, y => _.first(getTranslated(y.mediaContent, contentLanguage).thumbnails)) ?? emptyVideoThumbnail}
                                                        src={uri ?? ""}
                                                        controls preload="auto"
                                                        width="100%"
                                                        style={{ outline: "none" }}
                                                        crossOrigin="anonymous"
                                                        ref={vidRef}

                                                    >
                                                        {_.map(media.subtitles, (s, idx) => {
                                                            const l = _.find(s.links, l => l.ref === "self");
                                                            const label = t(`tube::${s.lng}`)
                                                            if (l && label) {
                                                                return (
                                                                    <track key={s.reference} id={s.lng} src={l?.uri} label={label} kind="captions" srcLang={s.lng} default={false} />
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </video>
                                                    :
                                                    <Image src={missingVideoSvg} mediaType="svg+xml" />
                                                }
                                            </T.ColumnContent>
                                        }
                                        {c.columnType === "Audio" &&
                                            <T.ColumnContent lang={contentLanguage}>
                                                <T.AudioContainerHeader>
                                                    <Icon name="audio" style={{ float: "left", marginRight: 10 }} />{t("audiofile")}
                                                </T.AudioContainerHeader>
                                                {uri ?
                                                    <audio
                                                        src={uri ?? ""}
                                                        controls
                                                        crossOrigin="anonymous"
                                                        controlsList="nodownload"
                                                        style={{ outline: "none", width: "100%", height: 40, background: "#F1F3F4" }}
                                                    />
                                                    :
                                                    <Image src={missingAudioSvg} mediaType="svg+xml" />
                                                }
                                            </T.ColumnContent>
                                        }
                                    </AutoGridColumn>

                                )
                            })}
                        </AutoGrid>
                    }
                </>
            }
        </div>
    );
}

export default WikiArticleSection;
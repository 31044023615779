import { PostController } from 'collaboration-service';
import AutocompleteSearch from 'components/AutocompleteSearch/AutocompleteSearch';
import SidebarTitleContainer from 'components/Sidebars/SidebarTitleContainer';
import { belowBreakpointOrEqual, Icon } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import Autosuggest, { SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { Actions } from 'services/ApplicationState/Actions';
import { ApplicationState, useAppDispatch, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { shallowCompare } from 'services/Helpers';
import { useImgI18N } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import { FeedSC as T } from './FeedSC';

const mapper = (state: ApplicationState) => ({
    feedSearchProps: state.feedState.searchProps,
});

interface FeedSearchByKeywordsProps {
}

const FeedSearchByKeywords = (p: FeedSearchByKeywordsProps) => {
    const { feedSearchProps } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("feed");
    const dispatch = useAppDispatch();
    const [search, setSearch] = React.useState<string>();

    React.useEffect(() => {
        setSearch(feedSearchProps?.search ?? "");
    }, [feedSearchProps?.search])

    const [suggestions, setSuggestions] = React.useState<string[]>([]);

    const startSearch = React.useMemo(() => async (search?: string) => {
        console.log("FeedSearchByKeywords.tsx::35 => search", search);

        dispatch(Actions.setFeedSearchProps({ ...feedSearchProps, search: search }));
    }, [dispatch, feedSearchProps]);

    const onSuggestionSelected = React.useMemo(() => (e: any, data: { suggestion: string }) => {
        if (search) {
            const prefixEnd = search.lastIndexOf(" ");
            let prefix = "";
            if (prefixEnd >= 0)
                prefix = search.slice(0, prefixEnd) + " ";
            startSearch(`${prefix}${data.suggestion}`);
        }
    }, [search, startSearch]);

    const onSuggestionsClearRequested = React.useMemo(() => () => {
        setSuggestions([]);
    }, []);

    const onSuggestionsFetchRequested = React.useMemo(() => _.debounce((request: SuggestionsFetchRequestedParams) => {
        if (request.reason === "input-changed" || request.reason === "input-focused") {
            let terms = request.value.split(" ");
            terms = _.filter(terms, t => t.length > 0);
            const lastTerm = _.last(terms);
            if (lastTerm) {
                PostController.SuggestPostTerm2({ term: lastTerm, filter: feedSearchProps?.filter ?? {} },
                    suggestions => setSuggestions(suggestions));
            }
        }
    }, 250),
        [feedSearchProps]);

    const searchChanged = React.useMemo(() => (event: React.FormEvent<any>, params: Autosuggest.ChangeEvent) => {
        setSearch(params.newValue);
    }, [setSearch]);

    const onEnterPressed = React.useMemo(() => () => {
        startSearch(search);
    }, [startSearch, search]);

    const onResetSearch = React.useMemo(() => () => {
        setSearch(undefined);
        startSearch(undefined);
    }, [startSearch, setSearch]);

    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "tablet");

    return (
        <>
            {!isMobile &&
                <SidebarTitleContainer icon="search" title={t("search by keyword")} />
            }
            <T.Auto
                // suggested={suggestions.length > 0 ? suggestions.length + 1 : 1} 
                style={{ marginTop: isMobile ? 10 : 0 }}
            >
                <AutocompleteSearch
                    placeholder={isMobile ? t("search by keyword") : t("search..")}
                    onSuggestionSelected={onSuggestionSelected}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    search={search ?? ""}
                    searchChanged={searchChanged}
                    suggestions={suggestions}
                    onEnterPressed={onEnterPressed}
                />
                {search !== "" && search !== undefined &&
                    <T.SearchDelete
                        onClick={search !== "" ? onResetSearch : undefined}
                    >
                        <Icon marginTop={9} name="times" size="20px" />
                    </T.SearchDelete>
                }
                <T.SearchIcon
                    onClick={search !== "" ? onResetSearch : undefined}
                >
                    <Icon marginTop={9} name="search" size="20px" />
                </T.SearchIcon>
            </T.Auto>
        </>
    )
}

export default FeedSearchByKeywords;

import * as _ from "lodash";

interface SKPair {
    reference: any;
    keeper: ScrollKeeper;
}

export default class ScrollKeeper {
    private static pairs: Array<SKPair> = [];

    public static get(reference: any) {
        const idx = _.findIndex(ScrollKeeper.pairs, p => _.isEqual(p.reference, reference));
        if (idx >= 0)
            return ScrollKeeper.pairs[idx].keeper;
        else {
            const keeper = new ScrollKeeper();
            this.pairs.push({ reference, keeper });
            return keeper;
        }
    }


    private scrollPos?: number;
    private scrollHeight?: number;
    private trys: number = 0;

    private ref: HTMLElement | HTMLDivElement | null | undefined;

    public savePosition = () => {
        // console.log("save!");
        // console.log("trys => ", this.trys);
        // console.log("ref => ", this.ref);
        if (this.trys > 0 || !this.ref)
            return;
        const t = this.ref as HTMLDivElement;
        if (t) {
            this.scrollHeight = t.scrollHeight;
            this.scrollPos = t.scrollTop;
            // console.log("saving height => ", this.scrollHeight);
            // console.log("saving pos => ", this.scrollPos);
        }
    }

    private scrollTo = (r?: HTMLElement | null) => {
        if (!r)
            return;
        this.trys--;
        // console.log("trys => ", this.trys);
        if (this.trys <= 0)
            return;
        if (this.scrollHeight && this.scrollPos) {
            if (r.scrollHeight >= this.scrollHeight) {
                r.scrollTop = this.scrollPos;
                this.trys = 0;
                this.scrollPos = 0;
                this.scrollHeight = 0;
            }
            else {
                setTimeout(() => this.scrollTo(r), 250);
            }
        }
        else {
            this.trys = 0;
            this.scrollPos = 0;
            this.scrollHeight = 0;
        }
    }

    public setScrollContainerAndChildrenContainerRef = (parent?: boolean) => (r: HTMLDivElement) => {
        let ele: HTMLElement | HTMLDivElement | null = r;
        if (r)
            ele = r.parentElement;
        if (ele)
            this.ref = ele;
        this.trys = 20;
        this.scrollTo(ele);
    }

    public reset = () => {
        this.scrollHeight = 0;
        this.scrollPos = 0;
        if (this.ref)
            this.ref.scrollTop = 0;
    }
}




import { store } from "App";
import { ControllerHelper, EventController, GroupController, GroupDownloadDto, UserController, UserDownloadDto, WikiController } from "collaboration-service";
import { LinkType } from "imaginarity-azure";
import * as _ from "lodash";
import { AnyAction } from "redux";
import { Actions } from "./ApplicationState/Actions";
import { PluginActions } from "./ApplicationState/PluginStateHandling";
import { visitGroups } from "./Helpers";
import { hasLink } from "./Helpers/LinkHelpers";

export const changeLastGroup = async (group?: GroupDownloadDto, actions?: AnyAction | AnyAction[], curUser?: UserDownloadDto) => {
    if (!group)
        return;
    const user = curUser ?? store.getState().user;
    if (user && user.userSettings) {
        const settings = user.userSettings;
        const a: AnyAction[] = [Actions.setCurrentGroup(group)];
        if (actions) {
            if (_.isArray(actions))
                a.push(...actions);
            else
                a.push(actions);
        }
        store.dispatch(PluginActions.batchActions(a));
        if (settings.lastGroupId !== group.id) {
            settings.lastGroupId = group.id;
            await ControllerHelper
                .addCall({ id: user.id, settings }, UserController.PostUserSettings)
                .addCall({ groupId: group.id, newAppType: group.groupType }, EventController.ApplicationChanged)
                .execute<UserDownloadDto, void>();
        }
    }
}

export const changeLastGroupToId = async (groupId?: string, actions?: AnyAction | AnyAction[], curUser?: UserDownloadDto) => {
    if (!groupId)
        return;
    const state = store.getState();
    const user = curUser ?? state.user;
    const groups = [...state.userGroups ?? [], ...state.adminGroups ?? []];
    const group = _.find(groups, gg => gg.id === groupId);
    if (!group)
        return;
    if (user && user.userSettings) {
        const settings = user.userSettings;
        const a: AnyAction[] = [Actions.setCurrentGroup(group)];
        if (actions) {
            if (_.isArray(actions))
                a.push(...actions);
            else
                a.push(actions);
        }
        store.dispatch(PluginActions.batchActions(a));
        if (settings.lastGroupId !== group.id) {
            settings.lastGroupId = group.id;
            await ControllerHelper
                .addCall({ id: user.id, settings }, UserController.PostUserSettings)
                .addCall({ groupId: group.id, newAppType: group.groupType }, EventController.ApplicationChanged)
                .execute<UserDownloadDto, void>();
        }
    }
}

export const getWikiGroupIdForId = async (id: string, nearestChildGroup: boolean) => {
    if (!id)
        return undefined;
    let groupId: string | undefined = undefined;
    if (!nearestChildGroup)
        groupId = await ControllerHelper.singleCall({ id }, GroupController.GetGroupIdForId);
    else
        groupId = await ControllerHelper.singleCall({ id }, WikiController.GetGroupById);
    if (groupId) {
        const group = _.find(store.getState().userGroups, g => g.id === groupId);
        //console.log("->", group);
        if (group && group.groupType === "WIKI")
            changeLastGroup(group);
        return groupId;
    }
    return undefined;
}

const changeToGroup = async (groupTypes: string | string[] | undefined, groups: GroupDownloadDto[] | undefined, actions: AnyAction | AnyAction[] | undefined, user: UserDownloadDto | undefined, currentGroup: GroupDownloadDto | undefined, groupAttribute?: string) => {
    const c = groupTypes ? _.isArray(groupTypes) ? groupTypes : [groupTypes] : undefined;
    let group: GroupDownloadDto | undefined;
    if (currentGroup && (c === undefined || _.findIndex(c, gt => gt === currentGroup.groupType && (groupAttribute === undefined || (currentGroup.attributes !== undefined && currentGroup.attributes[groupAttribute] !== undefined))) >= 0)) {
        return;
    }
    if (user && groups) {
        group = (_.find(groups, g => (c === undefined || _.find(c, cc => g.groupType === cc) !== undefined) && (groupAttribute === undefined || (g.attributes !== undefined && g.attributes[groupAttribute]))) as GroupDownloadDto);
        if (group) {
            await changeLastGroup(group, actions, user);
        }
    }
}

export const changeLastGroupToType = async (groupTypes?: string[] | string, actions?: AnyAction | AnyAction[], curUser?: UserDownloadDto, groupAttributes?: string) => {
    const state = store.getState();
    changeToGroup(groupTypes, state.userGroups, actions, curUser ?? state.user, state.currentGroup, groupAttributes);
}
export const changeLastGroupToTypeWithCheck = async (check: (g: GroupDownloadDto) => boolean, groupTypes?: string[] | string, actions?: AnyAction | AnyAction[], curUser?: UserDownloadDto, groupAttributes?: string) => {
    const state = store.getState();
    changeToGroup(groupTypes, _.filter(state.userGroups, check), actions, curUser ?? state.user, state.currentGroup, groupAttributes);
}


export const changeLastAdminGroupToType = async (groupTypes?: string | string[], actions?: AnyAction | AnyAction[], curUser?: UserDownloadDto, groupAttributes?: string) => {
    const state = store.getState();
    changeToGroup(groupTypes, adminGroupsOfType(groupTypes, state.adminGroups), actions, curUser ?? state.user, state.currentGroup, groupAttributes);
}


export const adminGroupsOfType = (groupTypes?: string[] | string, adminGroups?: GroupDownloadDto[]) => {
    const groups = adminGroups ?? store.getState().adminGroups;
    const toRet: GroupDownloadDto[] = [];
    const c = groupTypes ? _.isArray(groupTypes) ? groupTypes : [groupTypes] : undefined;
    visitGroups(groups, g => {
        if (c === undefined || c.indexOf(g.groupType) >= 0)
            toRet.push(g);
        return true;
    });
    return toRet;
}

export const groupsOfType = (groupTypes?: string[] | string, gs?: GroupDownloadDto[]) => {
    const groups = gs ?? store.getState().userGroups;
    const toRet: GroupDownloadDto[] = [];
    const c = groupTypes ? _.isArray(groupTypes) ? groupTypes : [groupTypes] : undefined;
    visitGroups(groups, g => {
        if (c === undefined || c.indexOf(g.groupType) >= 0)
            toRet.push(g);
        return true;
    });
    return toRet;
}


export const isAdmin = (groupTypes: string[] | string, adminGroups?: GroupDownloadDto[]) => {
    const groups = adminGroups ?? store.getState().adminGroups;
    let toRet = false;
    const c = _.isArray(groupTypes) ? groupTypes : [groupTypes];
    visitGroups(groups, g => {
        if (c.indexOf(g.groupType) >= 0) {
            toRet = true;
            return false;
        }
        return true;
    });
    return toRet;
}

export const isAdminInGroup = (groupId?: string): boolean | undefined => {
    const state = store.getState();
    const id = groupId ?? state.currentGroup?.id;
    if (!id)
        return undefined;
    let toRet = false;
    visitGroups(state.adminGroups, g => {
        if (id === g.id) {
            toRet = true;
            return false;
        }
        return true;
    });
    return toRet;
}

export const hasLinkInGroups = (name: LinkType, groupTypes?: string[]): boolean => {
    const state = store.getState();
    let toRet = false;
    visitGroups(state.userGroups, g => {
        if (hasLink(g, name) && (groupTypes === undefined || groupTypes.indexOf(g.groupType) >= 0)) {
            toRet = true;
            return false;
        }
        return true;
    });
    return toRet;
}

export const hasLinkInAdminGroups = (name: LinkType, groupTypes?: string[]): boolean => {
    const state = store.getState();
    let toRet = false;
    visitGroups(state.adminGroups, g => {
        if (hasLink(g, name) && (groupTypes === undefined || groupTypes.indexOf(g.groupType) >= 0)) {
            toRet = true;
            return false;
        }
        return true;
    });
    return toRet;
}


export const getGroupById = (groupId?: string, groups?: GroupDownloadDto[]): GroupDownloadDto | undefined => {
    const state = store.getState();
    const id = groupId ?? state.currentGroup?.id;
    const gs = groups ?? state.userGroups;
    if (!id)
        return undefined;
    let toRet = undefined;
    visitGroups(gs, g => {
        if (id === g.id) {
            toRet = g;
            return false;
        }
        return true;
    });
    return toRet;
}

export const hasAppEntry = (name: string) => {
    const ae = store.getState().appEntries;
    if (!ae)
        return false;
    return _.findIndex(ae, a => a.name === name) >= 0;
}

export const hasAppEntryByRoute = (route: string) => {
    const ae = store.getState().appEntries;
    if (!ae)
        return false;
    return _.findIndex(ae, a => a.route.startsWith(route)) >= 0;
}

export const getAppEntry = (name: string) => {
    const ae = store.getState().appEntries;
    if (!ae)
        return undefined;
    return _.find(ae, a => a.name === name);

}


export const isInGroup = (type: string) => {
    //console.log('STORE => ', store);
    const state = store.getState();
    //console.log('Helpers.tsx:423: => ', state);
    return _.findIndex(state.userGroups, g => g.groupType === type) >= 0;
};

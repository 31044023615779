import { appendPx, fadeColor, fadeIn, getColor, lightenColor, styled, TextArea } from "imaginarity-react-ui";

export class GeneralSC {
    public static CommentButtonContainer = styled.div<{ col?: string, noLeftBorder: boolean, padding: string }>`
    display: block;
    //padding-top:1rem;
    //padding-bottom:1rem;
    //padding-left:1rem;
    //padding-right:1rem;
    padding: ${p => p.padding};
    width: 100%;
    border-left: ${props => props.noLeftBorder ? "0px" : "3px"} solid ${props => getColor(props, props.col ?? props.theme.colors.accent)};
`;
    public static CommentBox = styled.div<{ bgColor?: string }>`
    display: block;
    width: 100%;
    height:82px;
    background: ${props => fadeColor(props, getColor(props, props.bgColor ?? props.theme.colors.accent), 3)};
    border: 1px solid ${props => fadeColor(props, props.bgColor ?? props.theme.colors.accent, 10)};
    position: relative;
    animation: ${fadeIn} 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.1s both;
`;

    public static CommentElement = styled.div<{ rowColor: boolean, noPosting?: boolean }>`
    display: block;
    button{
        display: ${p => p.noPosting === true ? "none" : undefined};
    }
`;
    public static CommentBoxLeft = styled.div`
    position: absolute;
    top:0;
    left: 0;
    width: calc(100% - 40px);
    height:82px;
`;
    public static CommentBoxUrlComp = styled.div<{ isInline: boolean }>`
    margin-left: ${p => p.isInline ? 190 : 40}px;
    margin-top: -20px;
    border-left: 3px solid ${p => getColor(p, p.theme.colors.accent)};
    padding-left: 10px;
    padding-top: 15px;
`;
    public static CommentBoxRight = styled.div<{ bgColor?: string, accent?: string }>`
    position: absolute;
    top:0px;
    right: 0;
    width:40px;
    background: ${props => fadeColor(props, getColor(props, props.accent ?? props.bgColor ?? props.theme.colors.accent), 5)};
    height:80px;
`;
    public static CommentTextArea = styled(TextArea) <{ darkMode?: boolean, color?: string }>`
    height:68px;
    font-size:0.9rem;
    color: ${props => props.color ?? props.darkMode ? getColor(props, props.theme.colors.mainBackground) : getColor(props, props.theme.colors.mainForeground)};
    font-weight: 400;
    margin: 5px 0;
    padding: 0 10px;
`;
    public static ErrorMessage = styled.div<{ accent?: string }>`
    float: right;
    margin-top:10px;
    color: ${props => props.accent ? props.accent : getColor(props, props.theme.colors.accentRed)};
`;
    public static ErrorMessageIcon = styled.div<{ accent?: string }>`
    float: right;
    margin-left:10px;
    margin-right: 8px;
    svg{
        fill: none !important;
        stroke: ${props => props.accent ? props.accent : getColor(props, props.theme.colors.accentRed)} !important;
    }
`;
    public static RatingSelectContainer = styled.div<{ color?: string }>`
    display: block;
    transition: all 0.2s ease;
    /* svg{
        transition: all 0.2s ease;
        stroke: ${props => getColor(props, props.color ?? props.theme.colors.accent)};
        fill: ${props => fadeColor(props, getColor(props, props.color ?? props.theme.colors.accent), 20)};
        &:hover{
            stroke: ${props => getColor(props, props.color ?? props.theme.colors.accent)};
            fill: ${props => fadeColor(props, getColor(props, props.color ?? props.theme.colors.accent), 80)};
        }
    } */
`;
    public static ResetSearchIcon = styled.div`
    position: absolute;
    top: 8px;
    right: 35px;
    cursor: pointer;
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static ResetSearchIconSearch = styled.div`
    position: absolute;
    top: 8px;
    left: 10px;
`;
    public static SidebarContainerTitle = styled.div`
    width:100%;
    font-weight: bolder;
    line-height: 32px;
    margin-top:5px;
`;
    public static ProgressContainer = styled.div`
    margin-top: 20px;
    width: 100%;
`;
    public static IconDef = styled.div`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 4;
    -ms-grid-column: 4;
    margin: auto;
    margin-right: 0px;
`;
    public static Header = styled.div`
    padding: 0;
    margin: 0px;
    position: relative;
    cursor: pointer;
    width: 100%;
    display: grid;
    display: -ms-grid;
    grid-template-columns: 25px auto 40px;
    grid-template-rows: 40px 1fr;
    -ms-grid-columns: 25px 1fr 40px;
    -ms-grid-rows:  40px 1fr;
`;
    public static HeaderIcon = styled.div`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 1;
    -ms-grid-column: 1;
    float: left;
`;
    public static HeaderText = styled.div`
    grid-row: 1;
    -ms-grid-row: 1;
    grid-column: 2;
    -ms-grid-column: 2;
    line-height:40px;
    font-weight: ${props => props.theme.fontWeights.heavy};
    font-size: ${props => appendPx(props.theme.fontSize)};
`;
    public static NoMarginButton = styled.div`
    button>div{margin: 0;}
`;













    public static UrlCompContainer = styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 75px 1fr 50px;
        grid-template-rows: 25px minmax(50px, max-content) 25px;
        background: ${p => lightenColor(p, p.theme.backgroundColor, 98)};
        color:${p => lightenColor(p, p.theme.color, 75)};
        border: 1px solid ${p => lightenColor(p, p.theme.backgroundColor, 75)};
        padding: 5px;
        margin: 10px 0;
        box-shadow: 0 6px 8px -6px ${p => lightenColor(p, p.theme.colors.mainForeground, 50)};
        transition: all 0.2s ease;
        cursor: pointer;
        &:hover{
            background: ${p => lightenColor(p, p.theme.backgroundColor, 95)};
            box-shadow: 0 6px 8px -6px  ${p => getColor(p, p.theme.colors.accentBlack)};
            border: 1px solid ${p => lightenColor(p, p.theme.backgroundColor, 70)};
        }
`;
    public static UrlCompContainerTitle = styled.div`
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
        line-height: 25px;
        padding-left: 5px;
        font-weight: bolder;
        font-size: 1em;
        border-bottom: 1px solid ${p => lightenColor(p, p.theme.backgroundColor, 80)};
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
`;
    public static UrlCompContainerIcon = styled.div`
        grid-column-start: 3;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 2;
        border-bottom: 1px solid ${p => lightenColor(p, p.theme.backgroundColor, 80)};
        &:hover{
            svg{
                stroke: ${p => getColor(p, p.theme.colors.hoverColor)};
            }
        }
`;
    public static UrlCompContainerImage = styled.div`
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 4;
        background: transparent;
        padding: 5px;
`;
    public static UrlCompContainerDescription = styled.div<{ noDesc: boolean }>`
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 3;
        line-height: 25px;
        padding-left: 5px;
        font-size: 0.9em;
`;
    public static UrlCompContainerLink = styled.div`
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 4;
        line-height: 25px;
        padding-left: 5px;
        font-size: 0.85em;
        border-top: 1px solid ${p => lightenColor(p, p.theme.backgroundColor, 80)};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover{
            color: ${p => getColor(p, p.theme.colors.accent)};
        }
`;
}
import { Dimmer, fadeColor, getColor, Icon } from 'imaginarity-react-ui';
import React from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div<{ width: number | string; height: number | string }>`
  position: relative;
  overflow: hidden;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
`;

const ImageWrapper = styled.div<{ translateX: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
`;

const Image = styled.img<{ contained?: boolean }>`
  width: ${({ contained }) => (contained ? '100vw' : '100%')};
  height: ${({ contained }) => (contained ? '100vh' : '100%')};
  object-fit: ${({ contained }) => (contained ? 'contain' : 'cover')};
  cursor: pointer;
  flex-shrink: 0;
`;

const NavButtonArea = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: ${p => fadeColor(p, "@darkGrey", 15)};
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ position }) => position}: 10px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${p => fadeColor(p, "@darkGrey", 35)};
  }
`;

const DotNavigation = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
`;
const DotNavigationSpan = styled.span<{ active: boolean }>`
    cursor: ${p => p.active ? "default" : "pointer"};
    svg{
        &:hover{
            stroke: ${p => getColor(p, p.active ? "@mainBackground" : "@accent")};
        }
    }
`;

interface ImageSliderProps {
    images: { url: string }[] | string;
    width: number | string;
    height: number | string;
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images, width, height }) => {
    const imageArray = typeof images === 'string' ? [{ url: images }] : images;
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const [startX, setStartX] = React.useState<number | null>(null);
    const [isDragging, setIsDragging] = React.useState(false);

    const toggleFullscreen = () => setIsFullscreen(!isFullscreen);

    const handleTouchStart = (e: React.TouchEvent) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        handleSwipeEnd(e.changedTouches[0].clientX);
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        setStartX(e.clientX);
        setIsDragging(true);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || startX === null) return;
    };

    const handleMouseUp = (e: React.MouseEvent) => {
        if (!isDragging) return;

        if (startX !== null && Math.abs(startX - e.clientX) < 10) {
            toggleFullscreen();
        } else {
            handleSwipeEnd(e.clientX);
        }

        setIsDragging(false);
        setStartX(null);
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
        if (isDragging) {
            handleSwipeEnd(e.clientX);
            setIsDragging(false);
        }
    };

    const handleSwipeEnd = (endX: number) => {
        if (startX === null) return;

        const deltaX = startX - endX;

        if (deltaX > 50 && currentIndex < imageArray.length - 1) {
            setCurrentIndex(currentIndex + 1);
        } else if (deltaX < -50 && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }

        setStartX(null);
    };

    React.useEffect(() => {
        document.body.style.overflow = isFullscreen ? 'hidden' : 'auto';
    }, [isFullscreen]);

    return (
        <SliderContainer width={width} height={height}>
            <ImageWrapper
                translateX={-currentIndex * 100}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                {imageArray.map((image, index) => (
                    <Image
                        key={index}
                        src={image.url}
                        alt={`Slide ${index}`}
                        loading="lazy"
                        onClick={(e) => {
                            if (startX !== null && Math.abs(startX - e.clientX) < 10) {
                                setCurrentIndex(index);
                                toggleFullscreen();
                            }
                        }}
                    />

                ))}
            </ImageWrapper>

            {imageArray.length > 1 && (
                <>
                    {currentIndex > 0 && (
                        <NavButtonArea position="left" onClick={() => setCurrentIndex(currentIndex - 1)}>
                            <Icon name="chevron left" size="50px" color="background" />
                        </NavButtonArea>
                    )}
                    {currentIndex < imageArray.length - 1 && (
                        <NavButtonArea position="right" onClick={() => setCurrentIndex(currentIndex + 1)}>
                            <Icon name="chevron right" size="50px" color="background" />
                        </NavButtonArea>
                    )}
                    <DotNavigation>
                        {imageArray.map((_, i) => (
                            <DotNavigationSpan active={i === currentIndex} onClick={i === currentIndex ? undefined : () => setCurrentIndex(i)} key={i} >
                                <Icon
                                    name={i === currentIndex ? "circle dot" : "circle"}
                                    color="@mainBackground"
                                    size={22}
                                    style={{ margin: "0 5px" }}

                                />
                            </DotNavigationSpan>
                        ))}
                    </DotNavigation>
                </>
            )}
            <Dimmer active={isFullscreen} onClick={toggleFullscreen} strong>
                <Image
                    src={imageArray[currentIndex].url}
                    alt={`Slide ${currentIndex}`}
                    contained
                    loading="lazy"
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                />
            </Dimmer>
        </SliderContainer>
    );
};

export default ImageSlider;

import { GroupDownloadDto } from 'collaboration-service';
import { belowBreakpointOrEqual, Button, fadeColor, fromThemeCreator, getColor, Icon, keyframes, lightenColor, styled } from 'imaginarity-react-ui';
import * as React from 'react';
import { FeedTheme } from 'views/Feed/FeedMain';

const selectFromTheme = fromThemeCreator<FeedTheme>("feedTheme");

export interface UserGroup { value: GroupDownloadDto, label: string }

export const Grid = styled.div`
    display: grid;
    grid-template-columns: ${props => belowBreakpointOrEqual(props, "tablet") ? "1fr" : "150px 1fr"}; 
    grid-template-rows: ${props => belowBreakpointOrEqual(props, "tablet") ? "min-content" : "max-content"}; 
    margin-bottom: 10px;
`;
export const BadgeContentType = styled.span`
    position: absolute;
    bottom:0px;
    border-top:1px solid ${props => getColor(props, props.theme.colors.veryLightGrey)};
    font-size:0.65rem;
    left: 30px;
    color: ${props => getColor(props, props.theme.colors.lightGrey)};
    text-transform: uppercase;
    letter-spacing: 1pt;
    z-index: 1;
    text-align: left;
    width: calc(100% - 2rem - 30px);
    height:40px;
    padding-top:6px;
  `;
export const PMPostInfosRating = styled.div<{ color: string }>`
  float: right;
  margin-left:10px;
  margin-right: -5px;
  margin-top:6px;
  transition: 0.1s all ease-in-out;
  cursor: pointer;
  svg{
    stroke: ${props => getColor(props, props.theme.colors.lightGrey)};
  }
  &:hover{
      svg{
        stroke: ${props => getColor(props, props.color)};
  } 
  }
`;
export const SettingContainer = styled.div<{ padded: boolean }>`
  position: absolute;
  height: 40px;
  width:220px;
  left:-220px;
  bottom: ${props => props.padded ? "0px" : "40px"};
  z-index:500;
  text-align: right;
`;

export const SCItem = styled.div`
  width:40px;
  display: inline-flex;
`;

export const TypeContainer = styled.div`
    font-size: 1.2rem;
  color: rgba(255,255,255,0.5);
  text-transform: uppercase;
  letter-spacing: 3px;
  position: absolute;
  top: 10px;
  left: 10px;
  transform: rotate(270deg) translateX(-100%);
  transform-origin: 0 0;
`;

export const IconFloated = styled(Icon) <{ color: string }>`
  float: left;
  margin-top: 2px; 
  margin-right: 5px;
  margin-bottom:10px;
  svg{
      stroke: ${props => getColor(props, props.color)}
      }
`;

export const SPContainer = styled.div<{ bgColor: string }>`
  display: grid;
  grid-template-columns: 10px 240px auto 40px;
  grid-auto-rows: auto;
  background: ${props => getColor(props, props.bgColor)};
`;

export const SPContainerMobile = styled.div<{ bgColor: string }>`
  display: grid;
  grid-template-columns: 1fr auto 0 40px;
  grid-auto-rows: auto;
  margin-top:-1em;
  background: ${props => getColor(props, props.bgColor)};
  position: relative;
`;

export const SPContainerSpecial = styled.div<{ bgColor: string }>`
  display: grid;
  grid-template-columns: 10px 250px auto 40px;
  grid-auto-rows: auto;
  margin-top:-1em;
  background: ${props => getColor(props, props.bgColor)};
`;

export const SPCType = styled.div<{ bgColor: string }>`
  grid-column: 1;
  grid-row: 1;
  background: ${props => props.bgColor};
`;

export const SPCImage = styled.div<{ imageUrl?: string, bgColor: string }>`
  grid-column: 2;
  grid-row: 1;
  ${props => props.imageUrl && `cursor: pointer;`} ; 
  background:  linear-gradient(130deg, ${props => fadeColor(props, getColor(props, props.bgColor), 20)} 0%, rgba(255,255,255,0.05) 70%), url("https://cdn.imaginarity.com/public/images/postBG.svg"); 
  background-repeat: repeat-y;
  background-position: top right;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%; 
`;
export const SPCIBackground = styled.div<{ imageUrl?: string, bgColor: string }>`
  width: 240px;
  height: 240px;
  ${props => props.imageUrl && `background: url(${props.imageUrl})`} ; 
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  text-align: right;
  transition: 0.9s all ease-in-out;
`;
export const SPCIBackgroundSpecial = styled.div<{ imageUrl?: string, bgColor: string }>`
  width: 520px;
  height: calc(520px * 9 / 16);
  height: 520px;
  ${props => props.imageUrl && `background: url(${props.imageUrl})`} ; 
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  text-align: right;
  transition: 0.9s all ease-in-out;
`;



export const SPCContent = styled.div`
  grid-column: 3;
  grid-row: 1;
  position: relative;
  background: ${props => getColor(props, props.theme.colors.mainBackground)};
`;

export const SPCContentMobile = styled.div`
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row: 2;
  position: relative;
  background: ${props => getColor(props, props.theme.colors.mainBackground)};
`;

export const SPCButtons = styled.div<{ color: string }>`
  grid-column: 4;
  grid-row: 1;
  background: ${props => getColor(props, props.theme.colors.veryLightGrey)};
  padding:0;
  text-align: center;
  transition: 0.1s all ease-in-out;
  position: relative;
  border-right:1px solid transparent;
  margin:0px;
  &:hover{
      background: ${props => lightenColor(props, getColor(props, props.theme.colors.veryLightGrey), 94)};
      border-right:1px solid ${props => getColor(props, props.color)};
  }
`;

export const ButtonBarRight = styled(Button)`
    display: block;
    margin: 0px;
    transition: 0.2s all ease-out;
    
`;

export const ContentTop = styled.div<{ type?: string, color: string }>`
  display: block;
  padding:1rem 2rem;
  width:100%;
  font-size: 0.7rem;
  line-height: 24px;
  /* ${props => props.type === "PorscheMomentPost" && `border-top: 10px solid ${props.color}`}; */
`;

export const ContentDate = styled.div`
  float:right;
  text-transform: uppercase;
`;
export const ContentUser = styled.div<{ type?: string, color: string }>`
  text-transform: uppercase;
  float:left;
`;
export const ContentHeadline = styled.div`
  display: block;
  padding:0.5rem 2rem 1rem 2rem;
  width:100%;
  font-size: 1.1rem;
  line-height: 1.5rem;
`;
export const ContentContent = styled.div`
  display: block;
  margin:0 2rem 3.5rem 2rem;
  width: calc(100% - 2rem - 30px);
  font-size: 0.9rem;
  line-height: 1.1rem;
  border-left:1px solid ${props => getColor(props, props.theme.colors.veryLightGrey)}; 
  padding-left:23px;
`;
export const ImageButtonsBg = styled.div<{ color: string }>`
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  height: 40px;
  text-align: right;
  background-color: rgba(255, 255, 255, .45);  
  backdrop-filter: blur(3px);
  svg{
    stroke: ${props => props.color};
  }
`;

export const ImageSecond = styled.div<{ imageUrl?: string, color: string }>`
  position:absolute;
  bottom:0;
  right:0;
  width: 40px;
  height: 40px;
  ${props => props.imageUrl && `cursor: pointer;`} ; 
  ${props => props.imageUrl && `background: url(${props.imageUrl})`} ; 
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
  transform-origin: bottom right;
  transition: 0.2s all ease-in;
  transform: translateX(0) scale(1);
      z-index:-2;
  &:hover{
      transform: translateX(0) scale(6);
      z-index:-1;
  }
`;


export const ImageThird = styled(ImageSecond)`
  right:40px;
  &:hover{
      transform: translateX(40px) scale(6);
  }
`;

export const PostInfos = styled.div<{ color: string }>`
  float: right;
  margin-left:10px;
  transform-origin: center;
  transition: 0.1s all ease-in-out;
  cursor: pointer;
  &:hover{
      color: ${props => getColor(props, props.color)};
      font-weight: bolder;
  }
`;
export const PostInfosRating = styled.div<{ color: string }>`
  float: right;
  margin-left:10px;
  margin-right: -5px;
  margin-top:6px;
  transition: 0.1s all ease-in-out;
  cursor: pointer;
  svg{
    stroke: ${props => getColor(props, props.theme.colors.lightGrey)};
  }
  &:hover{
      svg{
        stroke: ${props => getColor(props, props.color)};
  } 
  }
`;



export const SPCommentContainer = styled.div`
  display: ${props => Math.random() > 0.7 ? "none" : "block"};    
  width: calc(100%);
`;

export const IconCategoryCrumb = styled(Icon)`
  transform-origin: bottom;
  transform: scale(1.7);
  margin-left: 5px;
  margin-right: 5px;
`;

export const IsExcitePost = styled.div<{ color: string }>`
        font-size: 2.4rem;
        float:right;
        font-weight:800;
        font-style: italic;
        color: ${props => getColor(props, props.color)};
        padding: 10px 20px 25px 15px;
        width: fit-content;
        border-top:10px solid ${props => getColor(props, props.color)};
`;

export const BottomButton = styled.div`
    position: absolute;
    bottom:0;
`;

export const BottomOpenButton = styled.div<{ color: string }>`
    background:${props => props.color};
    svg{
      stroke: ${props => props.theme.colors.mainBackground};
    }
`;

const SlideInTop = keyframes`
from {
              transform: translateY(-40px) scaleY(1.0) scaleX(0.0);
              transform-origin: 50% 0%;
              filter: blur(40px);
              opacity: 0;
  }
  100% {
              transform: translateY(0) scaleY(1) scaleX(1);
              transform-origin: 50% 50%;
              filter: blur(0);
              opacity: 1;
  }  
`;




export const LuxuryContainer = styled.div`
    padding-top: 8px;
    position: relative;
    svg{
      stroke: ${props => getColor(props, props.theme.colors.accentBlack)};
    }
`;
export const LuxuryContainerCheckbox = styled.div`
    float: left;
    width: calc(100% - 80px);
`;
export const LuxuryContainerRibbon = styled.div<{ visible: boolean }>`
    transition: all 0.2s ease-out;
    position: absolute;
    right: 0px;
    top: -18px;
    opacity: ${p => p.visible ? 1 : 0};
    animation: ${p => p.visible ? SlideInTop : ""} 0.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
`;

export const OuterImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 240px;
    height: 240px;
`;

export const OuterImageContainerSpecial = styled.div`
    display: flex;
    flex-direction: row;
    width: 520px;
    height: 520px;
`;

export const InnerImageContainer = styled.div<{ imageUrl: string }>`
    flex: 1;
    height: 100%;
    ${props => props.imageUrl && `background-image: url(${props.imageUrl})`} ; 
    background-position: center;
    background-size: cover;
    background-repeat: none;
    filter: grayscale(0) opacity(0.9);
    transition: all 0.8s ease;
    &:hover{
        flex: 20;
        filter: grayscale(0) opacity(1);
    }
`;
export const PostContainerDivOuter = styled.div`
    margin: auto;
    width: 100%;
    padding: 15px 0;
    position: relative;
    margin-bottom: -1px;
`;
export const PostContainerDivOuterBg = styled.div`
    position: absolute;
    inset: 1px;
    background: linear-gradient(90deg, #fff 10%, rgb(240, 239, 247), #fff 90%);
`;

export const PostContainerDiv = styled.div`
    margin: auto;
    width: 100%;
    max-width: ${props => selectFromTheme(props, t => t.post.maxWidth, t => "640px")};
    min-height: 100px;
    position: relative;
`;
export const LightboxFooter = styled.div`
    color: ${props => getColor(props, props.theme.colors.mainBackground)};
`;
export const LightboxContainer = styled.div`
    padding:30px;   
`;
export const LightboxImage = styled.img`
    max-width: 80vw;
    max-height: 80vh;
`;
export const CPCreated = styled.div`
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    margin: -10px 0px 5px 10px;
    width: calc(100% - 80px);
`;
export const CPTitle = styled.div`
    display: flex;
    margin: 5px;
    width: calc(100% - 10px);
`;
export const CPDesc = styled.div`
    border-left: 5px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
`;
export const ComGridOuter = styled(Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
`;
export const ComICInputAlternative = styled.div`
    background: ${props => getColor(props, props.theme.colors.mainBackground)};
    padding: 0 10px;
    border-left: 3px solid ${props => getColor(props, props.theme.colors.accentRed)};
`;

export const GridOuter = styled(Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
    button>div{margin: 0;}
`;

export const GridImages = styled(Grid)`
    grid-template-rows: unset; 
    -ms-grid-rows: unset;
    margin-top: 15px;
`;

export const ThankYouContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
`;
export const ThankYouImage = styled.div`
    position: absolute;
    height: 100%;
`;
export const ThankYouTextTop = styled.div`
    position: absolute;
    color: ${p => belowBreakpointOrEqual(p, "mobile") ? getColor(p, p.theme.colors.accentBlack) : getColor(p, p.theme.colors.accent)};
    left: 0;
    top: 0;
    width: 100%;
    text-align: left;
    padding: 0 10px;
    background: rgba(232, 230, 229, 0.4);
    line-height: 36px;
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? "0.9rem" : "1.1rem"};
    font-weight: bolder;
`;
export const ThankYouTextBottom = styled(ThankYouTextTop)`
    left: unset;
    top: unset;
    text-align: right;
    right: 0;
    bottom: 0;
`;
export const Standard = styled.div<{ selected: boolean }>`
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    position: relative;
    padding-bottom: 56.25%;  
    transition: all 0.2s ease-out;
    cursor: pointer;
    box-shadow: ${p => p.selected ? "0 6px 8px -6px black" : "none"};
    &:hover{
        background: ${p => getColor(p, p.theme.colors.darkerGrey)};
    }
`;
export const StandardImage = styled.div<{ selected: boolean }>`
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    filter: grayscale(${p => p.selected ? 0 : 1});
    opacity: ${p => p.selected ? 1 : 0.5};
`;
export const StandardText = styled.div<{ selected: boolean }>`
    position: absolute;
    bottom: ${p => p.selected ? 0 : 50}%;
    left: 0;
    right: 0;
    height: 40px;
    line-height: 40px;
    transition: all 0.2s ease-out;
    text-transform: uppercase;
    transform: translateY(${p => p.selected ? 0 : 50}%);
    text-align: center;
    background: ${p => p.selected ? fadeColor(p, getColor(p, p.theme.colors.accent), 70) : "rgba(255,255,255,0.5)"};
    color: ${p => p.selected ? getColor(p, p.theme.colors.mainBackground) : getColor(p, p.theme.colors.darkerGrey)};
    font-weight: 600;
`;
export const GroupGrid = styled.div<{ selected: boolean, hooverable: boolean }>`
    background: ${p => p.hooverable ? p.selected ? getColor(p, p.theme.colors.veryLightGrey) : getColor(p, p.theme.colors.mainBackground) : "inherit"};
    display: grid;
    grid-template-columns: 40px 1fr 30px; 
    grid-template-rows: max-content; 
    transition: all 0.2s ease-out;
    cursor:  ${p => p.hooverable ? "pointer" : "default"};
    font-weight: ${p => p.selected ? 600 : "inherit"};
    &:hover{
        background: ${p => p.hooverable ? "rgba(0,0,0,0.1)" : "inherit"};
    }
`;
export const GroupGridLabel = styled.div`
    line-height: 40px;
    padding-left: 10px;
`;
export const GroupGridImage = styled.div`
    line-height: 40px;
    padding: 5px;
`;
export const Label = styled.div<{ color?: string }>`
    color: ${p => p.color ? getColor(p, p.color) : ""};
    font-weight: bolder;
    line-height:40px;
    font-size:0.9rem;
`;
export const InputContainer = styled.div<{ isMobile: boolean }>`
        margin-top:15px;
        width: 100%;
        padding: ${props => props.isMobile ? "0 5px" : 0};
`;
export const Types = styled.div`
    background-color: ${p => getColor(p, p.theme.colors.accent)};
    height: 80px;
    width: 100%;
    line-height: 40px;
`;
export const Standards = styled.div`
    font-size: 1.1rem;
    width: 100%;
    text-align: center;
    letter-spacing: 1.5px;
    color: ${p => getColor(p, p.theme.colors.veryLightGrey)};
`;
export const Type = styled.div<{ selected: boolean }>`
    background-color: ${p => p.selected ? lightenColor(p, getColor(p, p.theme.colors.accent), 80) : getColor(p, p.theme.colors.accent)};
    color: ${p => p.selected ? getColor(p, p.theme.colors.mainBackground) : fadeColor(p, getColor(p, p.theme.colors.mainBackground), 60)};
    height: 40px;
    line-height: 40px;
    width: 100%;
    font-weight: ${p => p.selected ? "500" : "300"};
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? "0.65rem" : belowBreakpointOrEqual(p, "tablet") ? "0.75rem" : "0.8rem"};
    letter-spacing: ${p => belowBreakpointOrEqual(p, "mobile") ? "unset" : "1px"};
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid ${p => p.selected ? fadeColor(p, getColor(p, p.theme.colors.accentBlack), 100) : fadeColor(p, getColor(p, p.theme.colors.darkGrey), 80)};
`;
export const MediaContainer = styled.div`
    position: relative;
`;
export const ContentContainer = styled.div`
    padding: 15px 0px 0px 10px;
`;
export const Header = styled.div`
    padding: 0px 10px 5px 10px;
    font-size: larger;
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const Info = styled.div`
    padding: 0 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const Contributors = styled.div`
    width: 100%;
    padding: 0 20px;
    line-height: 30px;
    background: rgba(0,0,0,0.02);
    font-size: 0.8rem;
`;
export const ContributorsItem = styled.div`
    display: inline-block;
    margin-right: 10px;
`;
export const Content = styled.div`
    border-left: 5px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin: 20px 10px 20px 10px;
    padding: 0 10px;
`;
export const CommunityPost = styled.div`
    height: 0px;    
`;
export const CommentsContainer = styled.div<{ open: boolean }>`
    border-top: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    height: ${p => p.open ? "100%" : "0px"};
    transform-origin: top;
    transform: scaleY(${p => p.open ? 1 : 0});
    width: 100%;   
    opacity: ${p => p.open ? "100%" : "0%"};
    transition: all 0.1s ease-in;
    margin-top: -1px;
`;
export const Card = styled.div<{ isMobile?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 290px;
    max-width: 100%;
    min-height: 45px;
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    padding: 0;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-left: ${p => p.isMobile ? -p.theme.sidebars.padding.left : 0}px;
`;
export const Comments = styled.div`
    margin: 0px;
    background: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    border-top: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    padding: 0px;
`;
export const LikeButton = styled.div<{ active?: boolean, float: "left" | "right" }>`
    height: 40px;    
    width: 40px;    
    float: ${p => p.float};
    color: ${p => p.active ? getColor(p, p.theme.colors.accentRed) : "inherit"};
    button>div{margin:0;}
    svg{
        //stroke: ${p => p.active ? getColor(p, p.theme.colors.accentRed) : "inherit"};
    }
`;
export const Indicator = styled.div<{ float?: "left" | "right", isType?: "button" | "rating" }>`
    float: ${p => p.isType === "rating" ? "right" : p.float ? p.float : "left"};
    margin-left: ${p => p.float === "left" || p.float === undefined ? "-40px" : p.isType === "rating" ? "-35px" : "-40px"};  
    margin-top: ${p => p.float === "left" || p.float === undefined ? "-2px" : p.isType === "rating" ? "-12px" : "-2px"};  
    width: 40px; 
    text-align: right;
    font-size: 0.65rem;
    padding: 0 3px;
    font-weight: bolder;
    pointer-events: none;
    color: ${p => lightenColor(p, getColor(p, p.theme.colors.middleLightGrey), 80)};
`;
export const Item = styled.div`
    height: 40px;
    line-height: 40px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover{
        background: rgba(0,0,0,0.05);
    }
`;
export const Created = styled.div`
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    margin: -10px 0px 5px 10px;
    width: calc(100% - 80px);
`;
export const SingleOrMulti = styled.div`
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    margin: 0px 0px 10px 10px;
    width: calc(100% - 80px);
`;
export const Title = styled.div`
    display: flex;
    margin: 5px;
    width: calc(100% - 80px);
`;

export const Desc = styled.div`
    border-left: 5px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 0px 10px;
`;
export const TPDesc = styled(Desc)`
    padding: 0px 80px 0 10px;
`;
export const CountText = styled.div<{ attention: boolean }>`
    display: block; 
    margin: 10px 0;
    width: 100%;
    padding-left: 3px;

    color: ${p => p.attention ? getColor(p, p.theme.colors.accentRed) : getColor(p, p.theme.colors.lightGrey)};
    font-weight: ${p => p.attention ? "bolder" : "normal"};
`;
export const StartButtonPost = styled.div`
    position: absolute;
    top: 10px !important;
    right: 10px !important;
    z-index: 10;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.1s all ease-out;
    transform-origin: center;
    transform: scale(1);
    filter: grayscale(0);
    &:hover{
        transform: scale(0.95);
        filter: grayscale(0.1);
    }
`;
export const GridContainer = styled.div`
    display: grid;
    display: -ms-grid;
    grid-template-columns: 30% 1fr;
    -ms-grid-columns: 30% 1fr;
`;
export const Publicated = styled.div`
    float: right;
    font-size: smaller;
    color: rgb(127, 127, 127);
    margin: -20px 10px;
`;
export const SPDesc = styled.div`
        border-left: 5px solid lightgray;
        margin-left: 10px;
        padding-left: 10px;
    `;
export const SPTitle = styled.div`
    display: flex;
    margin: 5px;
`;
export const SPCommentsRatingsBookmarks = styled.div`
    width: 100%;
    background-color: rgba(49,54,57,.07)!important;
`;
export const CommentsRatingsContent = styled.div`
    grid-row: 2;
    grid-column: 1 / span 3;
    border: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    margin-top:-1px;
`;
export const ButtonsContainer = styled.div`
    grid-column-start: 1;
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    background: ${p => getColor(p, p.theme.colors.mainBackground)};
    border-top: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    padding-top:2px;
`;
export const SPHeader = styled.div`
    margin: 5px;
    font-size: larger;
    font-weight: bold;
    width: 100%;
`;
export const PostRibbon = styled.div<{ gradientColor1: string, gradientColor2: string }>`
    position: absolute;
    left: -5px;
    top: -4px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
    font-style: italic;
    pointer-events:none;
    span {
        font-style: italic;
        font-weight: bold;
        color: #FFF;
        text-transform: lowercase;
        text-align: center;
        line-height: 20px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        width: 100px;
        display: block;
        background: #79A70A;
        background:  ${p => "linear-gradient(" + p.gradientColor1 + "," + p.gradientColor2 + ")"};
        box-shadow: 0 3px 10px -5px #000000;
        position: absolute;
        top: 19px;
        left: -21px;
        font-size: 0.8em;
    }
    span::before {
        content: "";
        position: absolute;
        left: 0px;
        top: 100%;
        z-index: -1;
        border-left:  ${p => "3px solid " + p.gradientColor1};
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-top:  ${p => "3px solid " + p.gradientColor1};
    }
    span::after {
        content: "";
        position: absolute;
        right: 0px;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-right: ${p => "3px solid " + p.gradientColor1};
        border-bottom: 3px solid transparent;
        border-top:  ${p => "3px solid " + p.gradientColor1};
    }
`;
export const Headline = styled.div`
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    font-size: larger;  
`;
export const NPCreated = styled.div`
    color: lightgray;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
`;
export const CreatedText = styled.div`
    margin-left: 10px;
`;
export const ImageContainer = styled.div`
    border: 1px solid lightgray;
    width: 133px;
    height: 76px;
    float: left;
    margin: 10px 10px 10px 10px;
`;
export const TextContainer = styled.div`
    font-size: 1.2em;
`;
export const SPContent = styled.div`
`;
export default class PostClasses extends React.Component<any> { }

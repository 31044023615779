import { belowBreakpointOrEqual, fadeColor, getColor, lightenColor, styled } from 'imaginarity-react-ui';
import { moduleBackgrounds } from 'services/Config';

export class FeedSC {
    public static ItemContainer = styled.div<{ toggle: boolean }>`
    display: grid;
    grid-template-columns: 30px 1fr 40px;
    width: 100%;
    margin-top: 0px;
    transition: all 0.2s ease-out;
    padding: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    /* svg{
        stroke: ${p => getColor(p, p.theme.color)};
    } */
    &:hover{
        background: ${p => lightenColor(p, getColor(p, p.theme.backgroundColor), 90)};
        /* svg{
            stroke: ${p => getColor(p, p.theme.color)};
    } */
    }
`;
    public static Avatar = styled.div`
    grid-column: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    line-height: 30px;
    padding-top: 5px;
    cursor: pointer;
`;




    public static NoResults = styled.div`
    position: absolute;
    height: 30vh;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    line-height: 30vh;
    font-size: ${p => belowBreakpointOrEqual(p, "mobile") ? 1.5 : 2}rem;
    background-image: url('${p => moduleBackgrounds.placeHolder}');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    color: ${p => fadeColor(p, getColor(p, p.theme.colors.accent), 70)};
`;
    public static UserContainer = styled.div`
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row: 1;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: ${p => getColor(p, p.theme.colors.veryLightGrey)};
    font-size: 0.9em;
    &:hover{
        color: ${p => getColor(p, p.theme.colors.mainBackground)};
    }
`;
    public static NotificationText = styled.div<{ asLink: boolean }>`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 2;
    line-height: 20px;
    cursor: ${p => p.asLink ? "pointer" : "default"};
    transition: all 0.2s ease-out;
    &:hover{
        color: ${p => p.asLink ? lightenColor(p, getColor(p, p.theme.color), 70) : "inherit"};
    }
`;
    public static LinkedButton = styled.div`
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;
    margin-right: -5px;
`;
    public static When = styled.div`
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row: 3;
    line-height: 20px;
`;

    public static Preview = styled.div<{ mobile?: boolean, left?: number }>`
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row: 4;
    height: 200px;
    overflow-y: hidden;
    width: 100%;
    overflow-x: hidden;
    margin-top: 10px;
`;
    public static PreviewContainer = styled.div`
    color: #000 !important;
    width: calc(100% * 2);
    height: calc(200px * 2);
    overflow-x: hidden;
    overflow-y: auto;
    transform-origin: top left;
    transform: scale(0.5);
    svg{
        stroke: #000 !important;
    }
`;

    public static Container = styled.div`
    display: block;
    padding-left:20px;
    padding-bottom:10px;
    line-height:30px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
    public static RadioButtonDescription = styled.div<{ selected: boolean }>`
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s ease-out;
    border-right: 2px solid transparent;
    color:${p => p.selected ? p.theme.colors.mainBackground : p.theme.colors.middleLightGrey};
    padding-right: 5px;
    &:hover{
        border-right: 2px solid ${p => p.theme.colors.darkerGrey};
        color:${p => p.theme.colors.mainBackground};
    }
`;
    public static RadioButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 30px 1fr; 
    svg{
        margin-top: 5px;
        float: left;
        }
    *{
        stroke: ${p => p.theme.colors.mainBackground};
    }
`;
    public static Auto = styled.div<{ suggested?: number }>`
    
    //height: ${p => p.suggested ? p.suggested * 40 + "px" : "40px"};
    height: ${p => p.suggested ? p.suggested * 40 + "px" : "auto"};
    float: left;
    color: ${p => p.theme.colors.mainForeground};
    width:100%;
    position: relative;
    margin-bottom: 5px;
    input:focus{
        background: ${p => lightenColor(p, p.theme.backgroundColor, 98)};
    }
    svg{
        stroke: ${p => getColor(p, p.theme.color)} !important;
        &:hover{
            stroke: ${p => getColor(p, p.theme.colors.hoverColor)} !important;
        }
    }
`;
    public static SearchDelete = styled.div`
    position: absolute;
    right: 2px;
    top: 1px;
    width: 38px;
    height: 38px;
    float: right;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    svg{
            stroke: ${p => getColor(p, p.theme.colors.lightGrey)};
        }
    &:hover{
        svg{
            stroke: ${p => getColor(p, p.theme.colors.accent)};
        }
    }
`;
    public static SearchIcon = styled.div`
    position: absolute;
    left: 3px;
    top: 1px;
    width: 38px;
    height: 38px;
    svg{
            stroke: ${p => getColor(p, p.theme.color)};
        }
`;
    public static FSBUContainer = styled.div<{ open: boolean, darkMode?: boolean }>`
display: block;
width: 100%;
    min-height: ${p => p.open ? 350 : 40}px;
    *{
        color: ${p => p.darkMode ? getColor(p, p.theme.colors.veryLightGrey) : getColor(p, p.theme.colors.darkerGrey)} !important;
    }
`;
    public static LLHeadline = styled.div`
    text-align: left;
    font-weight: bolder;
    color: ${p => getColor(p, p.theme.colors.mainForeground)};
    border-bottom: 1px solid ${p => getColor(p, p.theme.colors.middleLightGrey)};
    width: 100%;
    display: block;
    line-height: 24px;
    margin-bottom: 5px;
    svg{
        stroke: ${p => getColor(p, p.theme.colors.mainForeground)} !important;
    }
`;
}
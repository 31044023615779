import "App.css";
import { ControllerHelper, DefaultController, WikiController } from "collaboration-service";
import TestUCTS from "components/CategorySelection/TestUCTS";
import { ImgThemeProvider, Theme, getColor, imgIconDefs, styled } from 'imaginarity-react-ui';
import { applicationTheme } from 'index';
import * as _ from "lodash";
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { register } from "serviceWorkerRegistration";
import { AppRouteNames, ThisAppEntry, ViewRouteNames } from "services/AppEntryDefinitions";
import { Actions } from "services/ApplicationState/Actions";
import { MasterLayoutModule } from "services/ApplicationState/MasterLayout";
import { AppEntry } from "services/ApplicationState/MasterLayoutRouter";
import { PluginRegistry } from "services/ApplicationState/PluginRegistry";
import { PluginActions } from "services/ApplicationState/PluginStateHandling";
import { CurrentSettings, routeNames } from 'services/Config';
import { GlobalStyle } from 'services/GlobalStyle';
import HandleAppFocus from "services/HandleAppFocus";
import { AccessHelpers, getFrontEndSettingFromState, setFrontEndSetting } from 'services/Helpers';
import { getBrowser } from 'services/Helpers/BrowserHelper';
import { createCheckWhereIAm } from "services/Helpers/RoutingHelper";
import PageStateConnection from "services/PageStateConnection";
import ManageCategoryLike, { CategoryLikeTypes } from "views/Admin/ManageCategoryLike";
import { SsoAuthenticationFailedEntry } from 'views/AuthenticationFailed';
import { cmsConfig } from "views/CMS/CMSConfig";
//import { updateCMSSearchOnRoute } from "views/CMS/useCMSSearch/useCMSSearchHelpers";
import PDFFileEntryPlaylistView from "views/CMS/FileEntryPlaylistViews/PDFFileEntryPlaylistView";
import ContentFeedDetailPlaylistView from "views/ContentPage/ContentFeedDetailPlaylistView";
import DataPrivacy from "views/DataPrivacy/DataPrivacy";
import DataPrivacySidebar from "views/DataPrivacy/DataPrivacySidebar";
import { FallbackAppEntry } from "views/Fallback/Fallback";
import { GoToExternal } from 'views/GoToExternal';
import { HistoryViewAppEntry } from 'views/History/HistoryView';
import MyHomeStartJRR from "views/MyHome/MyHomeStartJRR";
import MyHomeStartMC from "views/MyHome/MyHomeStartMC";
import MyHomeStartPRC from "views/MyHome/MyHomeStartPRC";
import MyHomeStartUDD from "views/MyHome/MyHomeStartUDD";
import PlaylistContentInteraction from "views/Playlist/PlaylistContentInteraction";
import PlaylistDetail from "views/Playlist/PlaylistDetail";
import PlaylistOverview from "views/Playlist/PlaylistOverview";
import WikiPlaylistContentInfo from "views/Playlist/WikiPlaylistContentInfo";
import PodcastAudio from "views/Podcast/PodcastAudio";
import PCPlaylistView from "views/Podcast/PodcastAudioPlaylistView";
import PodcastFeed from "views/Podcast/PodcastFeed";
import PodcastLinkedVideo from "views/Podcast/PodcastLinkedVideo";
import { ResetPasswordAppEntry } from "views/ResetPassword";
import { tubeTheme } from 'views/Tube/TubeMain';
import TubeVideoPlaylistView from "views/Tube/TubeVideoPlaylistView";
import AppStart from './components/AppStart';
import { ApplicationState } from "./services/ApplicationState/ApplicationState";
import { reducer } from './services/ApplicationState/Reducer';
import { ImgI18N, useImgI18N } from "./services/ImgI18N";
import MobileHelper from "services/MobileHelper";
import MyHomeStartGLPR from "views/MyHome/MyHomeStartGLPR";


const CategoryEditSidebarInteractionButtons = React.lazy(() => import("components/PostEdit/TypeImplementations/CategoryEditSidebarInteractionButtons"));
const UserSidebarInteractionButtons = React.lazy(() => import("components/User/UserSidebarInteractionButtons"));
const CatTree = React.lazy(() => import("views/Admin/CatTree"));
const EditCategoryLike = React.lazy(() => import("views/Admin/EditCategoryLike"));
const EditCiaNews = React.lazy(() => import("views/Admin/EditCiaNews"));
const EditMail = React.lazy(() => import("views/Admin/EditMail"));
const GroupMails = React.lazy(() => import("views/Admin/GroupMails"));
const MailsInteraction = React.lazy(() => import("views/Admin/MailsInteraction"));
const BestPracticeSidebarInteractionButtons = React.lazy(() => import("views/BestPractice/BestPracticeSidebarInteractionButtons"));
const ChatSidebarInteractionButtons = React.lazy(() => import("views/Chat/ChatSidebarInteractionButtons"));
const CMSArchive = React.lazy(() => import('views/CMS/CMSArchive'));
const CMSAssignedTasks = React.lazy(() => import("views/CMS/CMSAssignedTasks"));
const CMSContent = React.lazy(() => import("views/CMS/CMSContent"));
const CMSRole = React.lazy(() => import("views/CMS/CMSRole"));
const CMSSidebarInteractionButtons = React.lazy(() => import("views/CMS/CMSSidebarInteractionButtons"));
const CMSWorkflow = React.lazy(() => import("views/CMS/CMSWorkflow"));
const CMSContentFilter = React.lazy(() => import("views/CMS/Filter/CMSContentFilter"));
const CmsSidebarFilter = React.lazy(() => import("views/CMS/Filter/CmsSidebarFilter"));
const FeedSidebarInteractionButtons = React.lazy(() => import("views/Feed/FeedSidebarInteractionButtons"));
const MyHomeSidebarButtons = React.lazy(() => import("views/MyHome/MyHomeSidebarButtons"));
const AddNews = React.lazy(() => import("views/News/AddNews"));
const PlaylistContentInfo = React.lazy(() => import("views/Playlist/PlaylistContentInfo"));
const PlaylistSidebarInteractionButtons = React.lazy(() => import("views/Playlist/PlaylistSidebarInteractionButtons"));
const QanaASidebarForumSelect = React.lazy(() => import("views/QandA/QanaASidebarForumSelect"));
const QandACategory = React.lazy(() => import("views/QandA/QandACategory"));
const QandASidebarInteractionButtons = React.lazy(() => import("views/QandA/QandASidebarInteractionButtons"));
const SlideEdit = React.lazy(() => import("views/SlideShow/SlideEdit"));
const SlideEditContentInteraction = React.lazy(() => import("views/SlideShow/SlideEditContentInteraction"));
const Slides = React.lazy(() => import("views/SlideShow/Slides"));
const Slideshow = React.lazy(() => import("views/SlideShow/Slideshow"));
const SlideshowAccessTokens = React.lazy(() => import("views/SlideShow/SlideshowAccessTokens"));
const SlideshowEdit = React.lazy(() => import("views/SlideShow/SlideshowEdit"));
const SlideshowContentInteraction = React.lazy(() => import("views/SlideShow/SlideshowContentInteraction"));
const SlideshowGroupPosts = React.lazy(() => import("views/SlideShow/SlideshowGroupPosts"));
const SlideshowInteraction = React.lazy(() => import("views/SlideShow/SlideshowInteraction"));
const SlideshowPostInfoButtons = React.lazy(() => import("views/SlideShow/SlideshowPostInfoButtons"));
const SlideshowSettings = React.lazy(() => import("views/SlideShow/SlideshowSettings"));
const WikiCopyGroup = React.lazy(() => import("views/Wiki/WikiCopyGroup"));
const WikiSidebarInteractionButtons = React.lazy(() => import("views/Wiki/WikiSidebarInteractionButtons"));
const WikiTranslationImport = React.lazy(() => import('views/Wiki/WikiTranslationImport'));
const WikiTranslationSend = React.lazy(() => import('views/Wiki/WikiTranslationSend'));
const WikiTranslationSidebarButtons = React.lazy(() => import('views/Wiki/WikiTranslationSidebarButtons'));
const GroupWorkflows = React.lazy(() => import("views/Workflow/GroupWorkflows"));
const GETest = React.lazy(() => import("components/PostEdit/Test/GETest"));
const OpenPost = React.lazy(() => import("views/OpenPost/OpenPost"));
const PorscheMomentAdd = React.lazy(() => import('components/Posts/PorscheMomentPost/PorscheMomentAdd'));
const PorscheMomentView = React.lazy(() => import('components/Posts/PorscheMomentPost/PorscheMomentView'));
const TubeUpload = React.lazy(() => import("components/Tube/TubeUpload"));
const PodcastUpload = React.lazy(() => import("components/Podcast/PodcastUpload"));
const TubeGroupPosts = React.lazy(() => import("components/GroupPosts/TubeGroupPosts"));
const PodcastGroupPosts = React.lazy(() => import("components/GroupPosts/PodcastGroupPosts"));
const UserManagement = React.lazy(() => import("views/Admin/UserManagement"));
const PPNUserCheck = React.lazy(() => import("views/Admin/PPNUserCheck"));
const TranslateNameSpaces = React.lazy(() => import("views/Admin/TranslateNameSpaces"));
const TubeUploadInteraction = React.lazy(() => import("components/Tube/TubeUploadInteraction"));
const PodcastUploadInteraction = React.lazy(() => import("components/Podcast/PodcastUploadInteraction"));
const Greeting = React.lazy(() => import('components/WelcomeRoad/Greeting'));
const TermsOfUse = React.lazy(() => import('components/WelcomeRoad/TermsOfUse'));
const WikiNewsAdd = React.lazy(() => import('components/Wiki/WikiNewsAdd'));
const GraphDemo = React.lazy(() => import('Dummies/GraphDemo'));
const PlAssign = React.lazy(() => import('Dummies/PLAssign'));
const Search = React.lazy(() => import('layout/parts/Search'));
const Div100vh = React.lazy(() => import("react-div-100vh"));
const AdminDemo = React.lazy(() => import('views/Admin/AdminDemo'));
const PAGStats = React.lazy(() => import('views/PAGStats/PAGStats'));
const BestPracticeAdd = React.lazy(() => import('views/BestPractice/BestPracticeAdd'));
const BestPracticeContentInfo = React.lazy(() => import('views/BestPractice/BestPracticeContentInfo'));
const BestPracticeDetail = React.lazy(() => import('views/BestPractice/BestPracticeDetail'));
const BestPracticeFilter = React.lazy(() => import('views/BestPractice/BestPracticeFilter'));
const BestPracticeMain = React.lazy(() => import('views/BestPractice/BestPracticeMain'));
const Chat = React.lazy(() => import('views/Chat/Chat'));
const ChatAdd = React.lazy(() => import('views/Chat/ChatAdd'));
const ChatContentInfo = React.lazy(() => import('views/Chat/ChatContentInfo'));
const ChatContentInteraction = React.lazy(() => import('views/Chat/ChatContentInteraction'));
const ChatMain = React.lazy(() => import('views/Chat/ChatMain'));
const CMS = React.lazy(() => import("views/CMS/CMS"));
const CMSContentInteraction = React.lazy(() => import("views/CMS/CMSContentInteraction"));
const CMSDirectory = React.lazy(() => import("views/CMS/CMSDirectory"));
const CMSTranslationBatches = React.lazy(() => import("views/CMS/CMSTranslationBatches"));
const CMSFile = React.lazy(() => import("views/CMS/CMSFile"));
const CMSProject = React.lazy(() => import("views/CMS/EditProject/CMSProject"));
const CMSRoles = React.lazy(() => import("views/CMS/CMSRoles"));
const CMSSidebarTreeSelect = React.lazy(() => import("views/CMS/CMSSidebarTreeSelect"));
const CMSTemplate = React.lazy(() => import("views/CMS/CMSTemplate"));
const CMSTemplates = React.lazy(() => import("views/CMS/CMSTemplates"));
const ContentFeed = React.lazy(() => import('views/ContentPage/ContentFeed'));
const ContentFeedDetail = React.lazy(() => import('views/ContentPage/ContentFeedDetail'));
const ContentFilter = React.lazy(() => import('views/ContentPage/ContentFilter'));
const MyHomeContentFilter = React.lazy(() => import('views/MyHome/MyHomeContentFilter'));
const Academy = React.lazy(() => import('views/ExternalApplications/Academy'));
const Smicro = React.lazy(() => import('views/ExternalApplications/Smicro'));
const PcnaMobileAcademy = React.lazy(() => import('views/ExternalApplications/PcnaMobileAcademy'));
const Faq = React.lazy(() => import('views/Faq/Faq'));
const Inbox = React.lazy(() => import('views/Inbox/InboxHome'));
const Feed = React.lazy(() => import('views/Feed/Feed'));
// const AppDownload = React.lazy(() => import('views/AppDownload/AppDownload'));
// const FeedFilterAllOld = React.lazy(() => import('views/Feed/FeedFilterAllOld'));
const FeedFilterAll = React.lazy(() => import('views/Feed/FeedFilterAll'));
const JoinView = React.lazy(() => import('views/JoinView/JoinView'));
const MyHomeStart = React.lazy(() => import('views/MyHome/MyHomeStart'));
const PLaylistEditHome = React.lazy(() => import('views/Playlist/Edit/PLaylistEditHome'));
const QandAOverview = React.lazy(() => import('views/QandA/QandAOverview'));
const CreateGameSinglePlayer = React.lazy(() => import('views/Quiz/CreateGameSinglePlayer'));
const QuizDetails = React.lazy(() => import('views/Quiz/QuizDetails'));
const QuizFilterSorting = React.lazy(() => import('views/Quiz/QuizFilterSorting'));
const QuizGame = React.lazy(() => import('views/Quiz/QuizGame'));
const QuizHome = React.lazy(() => import('views/Quiz/QuizHome'));
const QuizInvite = React.lazy(() => import('views/Quiz/QuizInvite'));
const QuizJoin = React.lazy(() => import('views/Quiz/QuizJoin'));
const QuizSelector = React.lazy(() => import('views/Quiz/QuizSelector'));
const ScormLegalNotes = React.lazy(() => import('views/Scorm/ScormLegalNotes'));
const ScormOverview = React.lazy(() => import('views/Scorm/ScormOverview'));
const ScormPlayer = React.lazy(() => import('views/Scorm/ScormPlayer'));
const GlobalSearchFilters = React.lazy(() => import('views/Search/GlobalSearchFilters'));
const GlobalSearchResults = React.lazy(() => import('views/Search/GlobalSearchResults'));
const Support = React.lazy(() => import('views/Support/Support'));
const ThePage = React.lazy(() => import("views/ThePage/ThePage"));
const ThePageContentInteraction = React.lazy(() => import('views/ThePage/ThePageContentInteraction'));
const ThePageFilter = React.lazy(() => import('views/ThePage/ThePageFilter'));
const TubeFeed = React.lazy(() => import('views/Tube/TubeFeed'));
const TubeLinkedVideo = React.lazy(() => import('views/Tube/TubeLinkedVideo'));
const TubePostInfoButtons = React.lazy(() => import('views/Tube/TubePostInfoButtons'));
const PodcastPostInfoButtons = React.lazy(() => import('views/Podcast/PodcastPostInfoButtons'));
const TubePostInfoUpload = React.lazy(() => import('views/Tube/TubePostInfoUpload'));
const TubeVideo = React.lazy(() => import('views/Tube/TubeVideo'));
const WikiArticle = React.lazy(() => import('views/Wiki/WikiArticle'));
const WikiCategory = React.lazy(() => import('views/Wiki/WikiCategory'));
const WikiContentInfo = React.lazy(() => import('views/Wiki/WikiContentInfo'));
const WikiContentInteraction = React.lazy(() => import('views/Wiki/WikiContentInteraction'));
const WikiEditArticle = React.lazy(() => import('views/Wiki/WikiEditArticle'));
const WikiFaq = React.lazy(() => import('views/Wiki/WikiFaq'));
const WikiNewArticle = React.lazy(() => import('views/Wiki/WikiNewArticle'));
const WikiRecently = React.lazy(() => import('views/Wiki/WikiRecently'));
// const UserProfileContentInteraction = React.lazy(() => import('./components/User/UserProfileContentInteraction'));
const UserStatus = React.lazy(() => import('./components/User/UserStatus'));
const WelcomeRoad = React.lazy(() => import('./components/WelcomeRoad/WelcomeRoad'));
const DemosMain = React.lazy(() => import("./Dummies/DemosMain"));
const CoreBackendTest = React.lazy(() => import("./Dummies/CoreBackendTest"));
const MasterLayout = React.lazy(() => import("./layout/MasterLayout"));
const MasterLayoutRouter = React.lazy(() => import('./layout/MasterLayoutRouter'));
const ActivityFeed = React.lazy(() => import('./views/Feed/ActivityFeed'));
const ManagePlaylists = React.lazy(() => import('./views/Playlist/ManagePlaylists'));
const PlaylistHome = React.lazy(() => import('./views/Playlist/PlaylistHome'));
const QandAElement = React.lazy(() => import('./views/QandA/QandAElement'));
const QandAQuestioner = React.lazy(() => import('./views/QandA/QandAQuestioner'));
const User = React.lazy(() => import('./views/User/User'));
const WikiHome = React.lazy(() => import('./views/Wiki/WikiHome'));
const AssetManagement = React.lazy(() => import('./views/AssetManagement/AssetManagement'));
//const AssetManagement = React.lazy(() => import('./views/AssetManagement/AddTubeAsset'));
const AssetContentInteraction = React.lazy(() => import('./views/AssetManagement/AssetContentInteraction'));
const AssetAdd = React.lazy(() => import('./views/AssetManagement/AssetAdd'));
const PlaylistAssignment = React.lazy(() => import('./views/Playlist/PlaylistAssignment'));

const QuizGroupPosts = React.lazy(() => import("views/Quiz/Admin/QuizGroupPosts"));
const QuizGroupInteraction = React.lazy(() => import("views/Quiz/Admin/QuizGroupInteraction"));
const QuizAndQuestionEdit = React.lazy(() => import("views/Quiz/Admin/QuizAndQuestionEdit"));

const MyHomeSidebarInteractionButtons = React.lazy(() => import("views/MyHome/MyHomeSidebarInteractionButtons"));
const VideoFileEntryPlaylistView = React.lazy(() => import("views/CMS/FileEntryPlaylistViews/VideoFileEntryPlaylistView"));
const ImageFileEntryPlaylistView = React.lazy(() => import("views/CMS/FileEntryPlaylistViews/ImageFileEntryPlaylistView"));


const x = false;

/*
const WaitingComponent = (Component: any, Loader: any) => {
  return (props: any) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
}

const Loader = () => <div>Loading!</div>;


const Feed = WaitingComponent(lazy(() => import("views/Feed/Feed")), Loader);
const SlideMain = WaitingComponent(lazy(() => import("./Dummies/SlideMain")), Loader);
const SlideWithId = WaitingComponent(lazy(() => import("./Dummies/SlideWithId")), Loader);

const DummyFeed = WaitingComponent(lazy(() => import("./Dummies/DummyFeed")), Loader);
const DummySMW = WaitingComponent(lazy(() => import("./Dummies/DummySMW")), Loader);
*/

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

export const store = createStore(
  reducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)),
);



// QandA CONFIG

interface AppProps {

}
interface AppState {
  colors: boolean;
  progress: number;
  msg: string;
  loadingDone: boolean;
  modules?: MasterLayoutModule[];
  showInfo: boolean;
}
export const IEWarning = styled.div`
  position: fixed;
    width: 100%;
    margin: auto;
    background: ${props => getColor(props, "@accentRed")};
    color: #FFF;
    font-size:0.8rem;
    line-height:32px;
    height:32px; 
    bottom:0px;
    text-align: center;
    cursor: row-resize;
    border-top:2px solid ${props => getColor(props, "@accentRed")};
    &:hover{
      background: ${props => getColor(props, "@darkGrey")};
      border-top:2px solid ${props => getColor(props, "@accentRed")};
    }
`;
export const availableForRouteName = (appName: AppRouteNames, viewName?: ViewRouteNames) => (s: ApplicationState) => s.currentAppEntry?.routeName === appName && (viewName === undefined || viewName === s.currentAppView?.routeName);
export const availableForNotInSubView = (appName: AppRouteNames) => (s: ApplicationState) => s.currentAppEntry?.routeName === appName && undefined === s.currentAppView;
export const availableForAppAndSubViews = (appName: AppRouteNames, routeNames: ViewRouteNames[]) => (s: ApplicationState) => s.currentAppEntry?.routeName === appName && (s.currentAppView === undefined || s.currentAppView.routeName === undefined || _.find(routeNames, r => r === s.currentAppView?.routeName) !== undefined);
export const availableOr = (...orMethods: Array<(s: ApplicationState) => boolean>) => (s: ApplicationState) => {
  for (let i = 0; i < orMethods.length; i++)
    if (orMethods[i](s))
      return true;
  return false;
}

const a1: MasterLayoutModule[] =
  [
    {
      icon: "playlist",
      name: "Your playlists Wiki",
      moduleRenderer: (p) => <WikiPlaylistContentInfo />,
      available: (s: ApplicationState) => {
        // if (s?.currentAppEntry?.name === "BestPractice" || s?.currentAppEntry?.name === "myhome")
        //   return false;
        if (s?.currentAppEntry?.name === "Wiki") {
          const pco = s.playlistsState?.playlistStates?.length ?? 0;
          const tco = s.playlistsState?.playlistStatesTopic?.length ?? 0;
          return (pco + tco) > 0;
        }
        else
          return false;
      },
      type: "SearchAndFilter",
      noAccordion: false,
      open: true
    },


    {
      icon: "cog",
      name: "PPX Campus Modules",
      moduleRenderer: (p) => CurrentSettings.sidebarNavi ? <MyHomeSidebarButtons /> : <div />,
      type: "ContentInteraction",
      available: availableForRouteName("myhome"),
      noAccordion: CurrentSettings.sidebarNavi ? false : true,
      open: true,
    },
    {
      icon: "filter",
      name: "Forum Select",
      moduleRenderer: (p) => <QanaASidebarForumSelect {...p} />,
      type: "ContentInfo",
      available: availableForRouteName("q&a"),
      noAccordion: true,
    },

    {
      icon: "users",
      name: "User Info",
      moduleRenderer: (p) => <QandAQuestioner {...p} />,
      type: "ContentInfo",
      available: availableForRouteName("q&a"),
      open: true,
      noAccordion: false,
    },

    {
      icon: "link",
      name: "site navigation",
      moduleRenderer: (p) => <TubePostInfoButtons {...p} />,
      //available: availableFor("Tube"),
      available: availableForAppAndSubViews("tube", ['tube_cat', 'tube_video_details',]),
      type: "UserInteraction",
      noAccordion: true,
      open: true,
    },
    {
      icon: "link",
      name: "site navigation1",
      moduleRenderer: (p) => <PodcastPostInfoButtons {...p} />,
      //available: availableFor("Tube"),
      available: availableForAppAndSubViews("podcast", ['podcast_cat', 'podcast_audio_details',]),
      type: "UserInteraction",
      noAccordion: true,
      open: true,
    },

    {
      icon: "link",
      name: "slide navigation",
      moduleRenderer: (p) => <SlideshowPostInfoButtons {...p} />,
      available: availableForRouteName('slideshow'),
      type: "UserInteraction",
      noAccordion: true,
      open: true,
    },

    {
      icon: "upload",
      name: "Upload",
      moduleRenderer: (p) => <TubePostInfoUpload {...p} />,
      available: () => tubeTheme.showUploadForm, //availableFor(tubeSettings.showUploadForm ? "Tube" : ""),
      type: "ContentContext",
      noAccordion: false,
    },





    {
      icon: "cog",
      name: "select project",
      moduleRenderer: (p) => <CMSSidebarTreeSelect />,
      available: availableForRouteName("cms"),
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },












    {
      icon: "info",
      name: "Q&A",
      moduleRenderer: (p) => <QandASidebarInteractionButtons />,
      available: availableForRouteName("q&a"),
      type: "ContentInteraction",
      noAccordion: true,
    },


    {
      icon: "info",
      name: "MyHome-Interaction",
      moduleRenderer: (p) => <MyHomeSidebarInteractionButtons />,
      available: availableForRouteName("myhome"),
      type: "ContentInteraction",
      noAccordion: true,
    },

    {
      icon: "info",
      name: "CMS-SB-Interaction-Buttons",
      moduleRenderer: (p) => <CMSSidebarInteractionButtons />,
      // available: () => true,
      available: availableForRouteName("cms"),
      type: "ContentInteraction",
      noAccordion: true,
    },

    {
      icon: "info",
      name: "CMS-Interaction",
      moduleRenderer: (p) => <CMSContentInteraction />,
      available: availableForRouteName("cms"),
      type: "ContentInteraction",
      noAccordion: true,
    },
    {
      icon: "cog",
      name: "CMS-Content",
      moduleRenderer: (p) => <CMSContentFilter />,
      available: availableForRouteName("cms", "cms_content"),
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },
    {
      icon: "filter",
      name: "CMS-SB-Search-Filter",
      moduleRenderer: (p) => <CmsSidebarFilter />,
      available: availableForNotInSubView("cms"),
      // available: availableOr(availableForNotInSubView("cms"), availableForRouteName("cms", "cms_dir")),
      type: "SearchAndFilter",
      noAccordion: true,
    },

    {
      icon: "info",
      name: "CMS-Assigned-Tasks",
      moduleRenderer: (p) => cmsConfig.showAssignedTasksOnSidebar ? <CMSAssignedTasks /> : <div />,
      available: availableForRouteName("cms"),
      type: "SearchAndFilter",
      noAccordion: true,
    },

    // {
    //   icon: "info",
    //   name: "ToDos on CMS",
    //   moduleRenderer: (p) => <ToDos toDoItems={[
    //     { responsible: "Edwin", task: "Homesite: Suchbegriff auch in Dateinamen (Bsp. Test)" },
    //     { responsible: "Sascha", task: "Homesite: add content type to search results in case it is not file type", },
    //     { responsible: "Alexey", task: "Templates: Bei Klick auf neues Template sollte der Edit Mode für dieses neue Template direkt geöffnet sein", done: true },
    //     { responsible: "Alexey", task: "Projectinfo: Es fehlt das Projektbild und die Members mit ihren Rollen", done: true },
    //     { responsible: "Alexey", task: "Fileview: Das Setzen der Kategorien nicht sofort ändern, erst nach Klick auf Save, X bricht Vorgang ab", done: true },
    //   ]} />,
    //   available: availableFor("CMS"),
    //   type: "ContentContext",
    //   noAccordion: true,
    // },

    {
      icon: "info",
      name: "PPE-SB-Interaction-Buttons",
      moduleRenderer: (p) => <WikiSidebarInteractionButtons />,
      available: availableForRouteName("wiki"),
      type: "ContentInteraction",
      noAccordion: true,
    },




    {
      icon: "info",
      name: "PPE-Interaction",
      moduleRenderer: (p) => <WikiContentInteraction />,
      available: availableForRouteName("wiki"),
      type: "ContentInteraction",
      noAccordion: true,
    },

    {
      icon: "info",
      name: "ThePage-Interaction",
      moduleRenderer: (p) => <ThePageContentInteraction />,
      available: availableForRouteName("PALMS"),
      type: "ContentInteraction",
      noAccordion: true,
    },
    {
      icon: "filter",
      name: "Filter",
      moduleRenderer: (p) => <ThePageFilter />,
      available: (s: ApplicationState) => !s.thePageState.detailId && s.currentAppEntry?.name === "Academy",
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },
    {
      icon: "info",
      name: "BestPractice-Info",
      moduleRenderer: (p) => <BestPracticeContentInfo />,
      available: availableForRouteName("best", "best_detail"),
      type: "ContentInfo",
      noAccordion: true,
    },

    {
      icon: "info",
      name: "BestPractice-Interaction-Buttons",
      moduleRenderer: (p) => <BestPracticeSidebarInteractionButtons />,
      available: availableForRouteName("best"),
      type: "ContentInteraction",
      noAccordion: true,
    },

    {
      icon: "chat",
      name: "Chats-Info",
      moduleRenderer: (p) => <ChatContentInfo />,
      available: availableForRouteName("chat"),
      type: "ContentInfo",
      noAccordion: true,
    },
    {
      icon: "info",
      name: "Chat-Interaction-Buttons",
      moduleRenderer: (p) => <ChatSidebarInteractionButtons />,
      available: availableForRouteName("chat"),
      type: "ContentInteraction",
      noAccordion: true,
    },
    {
      icon: "info",
      name: "Chat-Interaction",
      moduleRenderer: (p) => <ChatContentInteraction />,
      available: availableForRouteName("chat"),
      type: "ContentInteraction",
      noAccordion: true,
    },

    {
      icon: "info",
      name: "User-Interaction-Buttons",
      moduleRenderer: (p) => <UserSidebarInteractionButtons {...p} />,
      available: availableForRouteName("user"),
      type: "ContentInteraction",
      noAccordion: true,
    },
    // {
    //   icon: "info",
    //   name: "User-Interaction",
    //   moduleRenderer: (p) => <UserProfileContentInteraction {...p} />,
    //   available: availableFor("User"),
    //   type: "ContentInteraction",
    //   noAccordion: true,
    // },
    {
      icon: "info",
      name: "PPE-Info",
      moduleRenderer: (p) => <WikiContentInfo />,
      available: availableForRouteName("wiki"),
      type: "ContentInfo",
      noAccordion: true,
    },
    {
      icon: "info",
      name: "PL-Interaction-Buttons",
      moduleRenderer: (p) => <PlaylistSidebarInteractionButtons />,
      available: availableForRouteName("playlist"),
      type: "ContentInteraction",
      noAccordion: true,
    },



















    {
      icon: "info",
      name: "PL-Interaction",
      moduleRenderer: (p) => <DataPrivacySidebar />,
      available: availableForRouteName("dataPrivacy"),
      type: "ContentInfo",
      noAccordion: true,
    },




    {
      icon: "info",
      name: "PL-Interaction",
      moduleRenderer: (p) => <PlaylistContentInteraction />,
      available: availableForRouteName("playlist"),
      type: "ContentInteraction",
      noAccordion: true,
    },












    {
      icon: "playlist",
      name: "Your playlists",
      moduleRenderer: (p) => <PlaylistContentInfo />,
      available: availableForRouteName('playlist'),
      // available: (s: ApplicationState) => {
      //   // if (s?.currentAppEntry?.name === "BestPractice" || s?.currentAppEntry?.name === "myhome")
      //   //   return false;
      //   if (s?.currentAppEntry?.name === "Playlist") {
      //     const pco = s.playlistsState?.playlistStates?.length ?? 0;
      //     const tco = s.playlistsState?.playlistStatesTopic?.length ?? 0;
      //     return (pco + tco) > 0;
      //   }
      //   else
      //     return false;
      // },
      type: "ContentInteraction",
      noAccordion: true,
      open: true
    },









    {
      icon: "filter",
      name: "BestPractice-filter",
      moduleRenderer: (p) => <BestPracticeFilter />,
      available: availableForNotInSubView("best"),
      type: "SearchAndFilter",
      open: true,
      noAccordion: true,
    },
    {
      icon: "filter",
      name: "Search-filter",
      moduleRenderer: (p) => <GlobalSearchFilters />,
      available: availableForNotInSubView("search"),
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },
    {
      icon: "activities",
      name: "activities",
      moduleRenderer: (p) => <ActivityFeed />,
      // available: availableForNotInSubView("user"),
      available: s => (availableForNotInSubView("user")(s) && window.location.hostname !== "porsche-retail-impulse.com"),
      type: "ContentInfo",
      noAccordion: false,
      open: true
    },
    {
      icon: "filter",
      name: "Quiz-filter",
      moduleRenderer: (p) => <QuizFilterSorting />,
      //available: availableForNotInSubView("Quiz"),
      available: availableForAppAndSubViews("quiz", ["quiz_details"]),
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },

    {
      icon: "filter",
      name: "Content-filter",
      moduleRenderer: (p) => <ContentFilter />,
      available: availableForRouteName("content"),
      // availableFor
      // availableForNotInSubView
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },

    {
      icon: "filter",
      name: "MyHome-Content-filter",
      moduleRenderer: (p) => <MyHomeContentFilter />,
      available: availableForRouteName("myhome"),
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },

    {
      icon: "feed",
      name: "Community-Filter",
      moduleRenderer: (p) => <FeedFilterAll />,
      available: availableForNotInSubView("community"),
      type: "SearchAndFilter",
      noAccordion: true,
      open: true
    },
    {
      icon: "feed",
      name: "Community-Interaction",
      moduleRenderer: (p) => <FeedSidebarInteractionButtons />,
      available: availableForRouteName("community"),
      type: "ContentInteraction",
      noAccordion: true,
    },
    {
      icon: "activities",
      name: "useractivities",
      moduleRenderer: (p) => <ActivityFeed />,
      available: availableForRouteName("community"),
      type: "ContentInfo",
      noAccordion: false,
      open: false
    },
    {
      icon: "user",
      name: "Assetmanagement-Interaction",
      moduleRenderer: (p) => <AssetContentInteraction />,
      available: availableForRouteName("assets"),
      type: "ContentInteraction",
      noAccordion: true,
    },
    {
      icon: "language",
      name: "Translation",
      moduleRenderer: (p) => <WikiTranslationSidebarButtons />,
      available: availableForRouteName("wiki"),
      // available: availableForAppAndSubViews("Wiki", ["wiki_translation_send", "wiki_translation_import"]),
      type: "ContentInfo",
      noAccordion: true,
    },
    {
      icon: "video player",
      name: "TubeContent-Interaction",
      moduleRenderer: (p) => <TubeUploadInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableOr(availableForRouteName("admin", "admin_tube_upload"), availableForRouteName("admin", "admin_tube_group")),
    },
    {
      icon: "audio",
      name: "PodcastContent-Interaction",
      moduleRenderer: (p) => <PodcastUploadInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableOr(availableForRouteName("admin", "admin_podcast_upload"), availableForRouteName("admin", "admin_podcast_group")),
    },
    {
      icon: "slideshow",
      name: "Slideshow-Interaction",
      moduleRenderer: (p) => <SlideshowInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableOr(availableForRouteName("admin", "admin_slideshow_upload"), availableForRouteName("admin", "admin_slideshow_group")),
    },
    {
      icon: "slideshow",
      name: "SlideshowSettings-Interaction",
      moduleRenderer: (p) => <SlideshowSettings />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableForRouteName('slideshow', 'admin_slideshow_upload'),
    },
    {
      icon: "slideshow",
      name: "SlideshowAccessTokens-Interaction",
      moduleRenderer: (p) => <SlideshowContentInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableForRouteName('slideshow', 'slideshow_access_tokens'),
    },
    {
      icon: "slideshow",
      name: "SlideEditContentInteraction-Interaction",
      moduleRenderer: (p) => <SlideEditContentInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableForRouteName('slideshow', 'slideshow_slide_edit'),
    },

    {
      icon: "mail",
      name: "Mails-Interaction",
      moduleRenderer: (p) => <MailsInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableOr(availableForRouteName("admin", "admin_group_mails"), availableForRouteName("admin", "admin_edit_mail")),
    },
    {
      icon: "edit",
      name: "Category-Edit-Interaction-Buttons",
      moduleRenderer: (p) => <CategoryEditSidebarInteractionButtons />,
      available: availableForRouteName("admin", "admin_edit_category_like"),
      type: "ContentInteraction",
      noAccordion: true,
    },



    {
      icon: "questionark",
      name: "Quiz-Interaction",
      moduleRenderer: (p) => <QuizGroupInteraction />,
      type: "ContentInteraction",
      noAccordion: true,
      available: availableForRouteName("admin", "admin_quiz_group"),
    },


  ];

const feed = (): ThisAppEntry => ({
  icon: "feed",
  name: "Community",
  startPriorityWeight: 2,
  contentRenderer: () => <Feed />,
  title: "application::community",
  titleStyle: {
    //fontStyle: "italic",
    //fontWeight: 600,
  },
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/momentsScreen.png",
  route: "community",
  routeName: "community",

  right: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("community_add_post"))
          return s.feedState.rightSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "adminRightSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("community_add_post"))
          store.dispatch(Actions.setFeedRightSidebar(!s.feedState.rightSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "adminRightSidebar", (c) => !c);
      }
    }
  },
  accessCheck: AccessHelpers.isInGroup(["EXCITE", "COMMUNITY"]),
  themeManipulation: (t: Theme) => ({
    ...t,
    mainArea: {
      ...t.mainArea,
      backgroundColor: t.applicationTheming.feedTheme?.backgroundColor ?? t.mainArea.backgroundColor,
      color: t.applicationTheming.feedTheme.color ?? t.mainArea.color,
    }
  }),
  views: [
    {
      render: () => <Feed />,
      route: "trending",
      routeName: "community_feed_trending"
    },
    {
      route: "add/:id?",
      render: () => <PorscheMomentAdd />,
      routeName: "community_add_post",
    },
    {
      route: "view/:id",
      render: () => <PorscheMomentView />,
      routeName: "community_view_post"
    },
    {
      route: "activities",
      routeName: "community_activities",
      render: () => <ActivityFeed />,
    }
  ]
})
// const appDownload = (): ThisAppEntry => ({
//   icon: "download",
//   name: "AppDownload",
//   startPriorityWeight: 2,
//   contentRenderer: () => <AppDownload />,
//   title: "application::native app",
//   left: {
//     hidden: true,
//   },
//   right: {
//     hidden: true
//   },
//   titleStyle: {
//     //fontStyle: "italic",
//     //fontWeight: 600,
//   },
//   route: "app",
//   routeName: "app_download",
//   accessCheck: () => { const b = getBrowser(); return b.os === "iOS" && b.name !== "ios-webview"; }
// })


const quiz = (): ThisAppEntry => ({
  name: "Quiz",
  startPriorityWeight: 2,
  title: "application::quiz",
  icon: "questionark",
  routeName: "quiz",
  route: "quiz",
  noLogin: false,
  hidden: false,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/quizScreen.png",
  themeManipulation: (t: Theme) => ({
    ...t,
    mainArea: {
      ...t.mainArea,
      backgroundColor: t.applicationTheming.quizTheme?.backgroundColor ?? t.mainArea.backgroundColor,
      color: t.applicationTheming.quizTheme.color ?? t.mainArea.color,
    }
  }),
  accessCheck: AccessHelpers.isInGroup("QUIZ"),

  right: {
    hidden: true,
  },

  contentRenderer: () => <QuizHome />,
  views: [
    {
      route: ":id",
      routeName: "quiz_game",
      render: () => <QuizGame />
    },
    {
      route: "single/:quizId/:mode?/:context?",
      routeName: "quiz_singleplayer",
      render: () => <CreateGameSinglePlayer />
    },
    {
      route: "multi/:quizId/:mode?",
      routeName: "quiz_multiplayer",
      render: () => <QuizInvite />
    },
    {
      route: "join/:gameId",
      routeName: "quiz_join",
      render: () => <QuizJoin />
    },
    {
      route: "selector/:quizId",
      routeName: "quiz_selector",
      render: () => <QuizSelector />
    },
    {
      route: "detail/:quizId",
      routeName: "quiz_details",
      render: () => <QuizDetails />
    },
  ]
})

const smirco = (): ThisAppEntry => ({
  name: "iTrainer",
  startPriorityWeight: 1,
  titleStyle: {
    textTransform: "unset",
  },
  icon: "globe",
  route: "itrainer",
  routeName: "smicro",
  title: "application::smicro",
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/itrainerScreen.png",
  contentRenderer: () => <Smicro />,
  accessCheck: AccessHelpers.isInGroup(["SSOAPP:smicro"]),
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})

const myhome = (): ThisAppEntry => ({
  startPriorityWeight: 6,
  name: "myhome",
  icon: "home",
  route: "myhome/:ppnId?",
  noLogin: false,
  routeName: "myhome",
  hidden: false,
  title: "myhome::myhome",
  contentRenderer: () => <MyHomeStart />,
  // left: {
  //   hidden: false,
  // },
  left: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("myhome_reports"))
          return s.myHomeState.leftSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "myhomeLeftSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("myhome_reports"))
          store.dispatch(Actions.setMyHomeSetLeftSidebar(!s.myHomeState.leftSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "myhomeLeftSidebar", (c) => !c);
      }
    }
  },
  // right: {
  //   hidden: false,
  // },
  right: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("myhome_reports"))
          return s.myHomeState.rightSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "myhomeRightSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("myhome_reports"))
          store.dispatch(Actions.setMyHomeSetRightSidebar(!s.myHomeState.rightSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "myhomeRightSidebar", (c) => !c);
      }
    }
  },
  views: [
    {
      route: "news/:id?",
      routeName: "myhome_news",
      render: () => <AddNews
        namespace="myhome"
        namedRoute="myhome"
        methodDelete={WikiController.DeleteWikiNews}
        methodPatch={WikiController.PatchWikiNews}
        methodPost={WikiController.PostWikiNews}
      />
    },
    {
      route: "myhome_prc",
      routeName: "myhome_prc",
      render: () => <MyHomeStartPRC />
    },
    {
      route: "myhome_udd",
      routeName: "myhome_udd",
      render: () => <MyHomeStartUDD />
    },
    {
      route: "myhome_mc",
      routeName: "myhome_mc",
      render: () => <MyHomeStartMC />
    },
    {
      route: "myhome_jrr",
      routeName: "myhome_jrr",
      render: () => <MyHomeStartJRR />
    },
    {
      route: "myhome_reports",
      routeName: "myhome_reports",
      render: () => <MyHomeStartGLPR />
    },
  ],
})

const dataPrivacy = (): ThisAppEntry => ({
  startPriorityWeight: 6,
  name: "dataPrivacy",
  icon: "data privacy",
  route: "dataPrivacy",
  noLogin: false,
  routeName: "dataPrivacy",
  hidden: true,
  title: "dataPrivacy::dataPrivacy",
  contentRenderer: () => <DataPrivacy />,
  left: {
    hidden: false,
  },
  right: {
    hidden: true,
  },
})


const academy = (): ThisAppEntry => ({
  name: "academy",
  icon: "globe",
  route: "academy",
  title: "application::academy",
  routeName: "PALMS",
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/academyScreen.png",
  contentRenderer: () => <Academy />,
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})


const pcnaMobileAcademy = (): ThisAppEntry => ({
  name: "pcnaMobileAcademy",
  startPriorityWeight: 1,
  icon: "globe",
  route: "pcnaMobileAcademy",
  title: "application::pcnaMobileAcademy",
  routeName: "pcnaMobileAcademy",
  // banner: "https://cdn.imaginarity.com/public/thepage/myhome/pcnaScreen.png",
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/pcnaScreen2.png",
  contentRenderer: () => <PcnaMobileAcademy />,
  accessCheck: AccessHelpers.isInGroup(["SSOAPP:PCNA-Mobile-Academy"]),
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})

const support = (): ThisAppEntry => ({
  name: "support",
  icon: "zoom in",
  route: "support",
  title: "application::support",
  routeName: "support",
  contentRenderer: () => <Support />,
  accessCheck: AccessHelpers.isInGroup("SUPPORT"),
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})

const openPost = (): ThisAppEntry => ({
  name: "OpenPost",
  icon: "content aggregation",
  route: "open/:id",
  title: "application::post edit",
  noLogin: false,
  routeName: "open_post",
  hidden: true,
  contentRenderer: () => <OpenPost />,
})

const peTest = (): ThisAppEntry => ({
  name: "PostEdit",
  icon: "users",
  route: "postEdit/:id?/:isCat?",
  title: "application::post edit",
  noLogin: false,
  routeName: "peTest",
  contentRenderer: () => <GETest />,
})

const welcome = (): ThisAppEntry => ({
  name: "Welcome",
  icon: "user",
  route: "welcome/:stepid/:token",
  title: "application::welcome",
  noLogin: true,
  hidden: true,
  renderDirectly: true,
  routeName: "welcome",
  contentRenderer: () => <WelcomeRoad />,
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})

const termsOfUse = (): ThisAppEntry => ({
  name: "Terms of Use",
  icon: "user",
  route: "tou",
  title: "application::termsofuse",
  noLogin: true,
  hidden: true,
  renderDirectly: true,
  routeName: "termsOfUse",
  contentRenderer: () => <TermsOfUse />,
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})


const greeting = (): ThisAppEntry => ({
  name: "Greeting",
  icon: "user",
  route: "greeting",
  routeName: "greeting",
  title: "application::greeting",
  noLogin: true,
  hidden: true,
  renderDirectly: true,
  contentRenderer: () => <Greeting />,
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})

const joinView = (): ThisAppEntry => ({
  name: "Join",
  icon: "user",
  route: "join/:itemid/:p1/:p2?/:p3?/:p4?/:p5?/:p6?/:p7?/:p8?/:p9?/:p10?/:p11?/:p12?/:p13?/:p14?/:p15?",
  routeName: "join",
  title: "application::join",
  hidden: true,
  renderDirectly: true,
  contentRenderer: () => <JoinView />,
  left: {
    hidden: true,
  },
  right: {
    hidden: true,
  },
})

const demos = (): ThisAppEntry => ({
  name: "Demos",
  icon: "info brain",
  route: "demos", ///:name?",
  title: "application::demos",
  hidden: false,
  noLogin: false,
  routeName: "demos",
  contentRenderer: () => <DemosMain />,
  right: {
    hidden: false,
  },
  left: {
    hidden: false,
  },
  views: [
    {
      route: "graph",
      routeName: "demos_graph",
      render: () => <GraphDemo />
    },
    {
      route: "plassign",
      routeName: "demos_plassign",
      render: () => <PlAssign />
    },
    {
      route: "coreBackendTest",
      routeName: "demos_coreBackendTest",
      render: () => <CoreBackendTest />
    },
    {
      route: ":name",
      routeName: "demos_demo", // + nr,
      render: () => <DemosMain />
    }
  ]
})

const playlist = (): ThisAppEntry => ({
  name: "Playlist",
  icon: "info brain",
  // route: "playlist/:type?/:id?",
  title: "application::playlist",
  route: "playlist",
  routeName: "playlist",
  hidden: !CurrentSettings.showPlaylistInMenu,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/playlistScreen.png",
  noLogin: false,
  right: {
    // hidden: true
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("playlist_edit") || c("playlist_manage") || c("playlist_podcast_audio_details"))
          return s.playlistsState.rightSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "playlistRightSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("playlist_edit") || c("playlist_manage") || c("playlist_podcast_audio_details"))
          store.dispatch(Actions.setPlaylistRightSidebar(!s.playlistsState.rightSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "playlistRightSidebar", (c) => !c);
      }
    }
  },
  left: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("playlist_edit") || c("playlist_manage") || c("playlist") || c("playlist_detail"))
          return s.playlistsState.leftSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "playlistLeftSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("playlist_edit") || c("playlist_manage") || c("playlist") || c("playlist_detail"))
          store.dispatch(Actions.setPlaylistLeftSidebar(!s.playlistsState.leftSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "playlistLeftSidebar", (c) => !c);
      }
    }
  },
  accessCheck: AccessHelpers.isInGroup("WIKI"),
  // contentRenderer: () => <PlaylistHome />,
  // contentRenderer: () => PlaylistSettings.showNewPlaylistOverview ? <PlaylistOverview /> : <PlaylistHome />,
  contentRenderer: () => CurrentSettings.activateNewPlaylist ? <PlaylistOverview /> : <PlaylistHome />,

  views: [
    {
      route: "editPlaylist/:id?",
      routeName: "playlist_edit",
      render: () => <PLaylistEditHome />,
    },
    {
      route: "showPlaylist/:type?/:id?",
      routeName: "playlist_show",
      // render: () => <PlaylistHome />
      // render: () => PlaylistSettings.showNewPlaylistOverview ? <PlaylistOverview /> : <PlaylistHome />
      render: () => CurrentSettings.activateNewPlaylist ? <PlaylistOverview /> : <PlaylistHome />
    },
    {
      route: "playlistDetail/:id?",
      routeName: "playlist_detail",
      render: () => <PlaylistDetail />
    },
    {
      route: "managePlaylists",
      routeName: "playlist_manage",
      render: () => <ManagePlaylists />
    },
    {
      route: "assignPlaylist/:id",
      routeName: "playlist_assign",
      render: () => <PlaylistAssignment />
    },
    {
      route: "content/art/:context/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "playlist_content_art",
      themeManipulation: (t: Theme) => ({
        ...t,
        mainArea: {
          ...t.mainArea,
          backgroundColor: t.applicationTheming.contentTheme?.backgroundColor ?? t.mainArea.backgroundColor,
          color: t.applicationTheming.contentTheme.color ?? t.mainArea.color,
        }
      }),
      render: () => <ContentFeedDetailPlaylistView />
    },
    {
      route: "video/:context/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "playlist_tube_video_details",
      themeManipulation: (t: Theme) => ({
        ...t,
        mainArea: {
          ...t.mainArea,
          backgroundColor: t.applicationTheming.tubeTheme?.backgroundColor ?? t.mainArea.backgroundColor,
          color: t.applicationTheming.tubeTheme.color ?? t.mainArea.color,
        }
      }),
      render: () => <TubeVideoPlaylistView />
    },
    {
      route: "video_fe/:context/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "playlist_fileentry_video_details",
      themeManipulation: (t: Theme) => ({
        ...t,
        mainArea: {
          ...t.mainArea,
          backgroundColor: t.applicationTheming.tubeTheme?.backgroundColor ?? t.mainArea.backgroundColor,
          color: t.applicationTheming.tubeTheme.color ?? t.mainArea.color,
        }
      }),
      render: () => <VideoFileEntryPlaylistView />
    },
    {
      route: "pdf_fe/:context/:id",
      routeName: "playlist_fileentry_pdf",
      render: () => <PDFFileEntryPlaylistView />
    },
    {
      route: "image_fe/:context/:id",
      routeName: "playlist_fileentry_image",
      render: () => <ImageFileEntryPlaylistView />
    },
    {
      route: "podcast_fe/:context/:id",
      routeName: "playlist_fileentry_podcast",
      themeManipulation: (t: Theme) => ({
        ...t,
        mainArea: {
          ...t.mainArea,
          backgroundColor: t.applicationTheming.podcastTheme?.backgroundColor ?? t.mainArea.backgroundColor,
          color: t.applicationTheming.podcastTheme.color ?? t.mainArea.color,
        }
      }),
      render: () => <VideoFileEntryPlaylistView />
    },
    {
      route: "audio/:context/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "playlist_podcast_audio_details",
      themeManipulation: (t: Theme) => ({
        ...t,
        mainArea: {
          ...t.mainArea,
          backgroundColor: t.applicationTheming.tubeTheme?.backgroundColor ?? t.mainArea.backgroundColor,
          color: t.applicationTheming.tubeTheme.color ?? t.mainArea.color,
        }
      }),
      render: () => <PCPlaylistView />
    },

  ]
})

const slide = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "Slides",
  icon: "slideshow",
  route: "slides",
  routeName: "slideshow",
  title: "application::slideshow",
  // banner: "https://cdn.imaginarity.com/public/images/PAG_X.svg",
  noLogin: false,
  hidden: false,
  accessCheck: AccessHelpers.isInGroup("SLIDESHOW"),
  contentRenderer: () => <Slides />,
  views: [
    {
      route: "slideshow/:groupid/:id?",
      routeName: "admin_slideshow_upload",
      render: () => <SlideshowEdit />
    },
    {
      route: "access/:id",
      routeName: "slideshow_access_tokens",
      render: () => <SlideshowAccessTokens />
    },
    {
      route: "slide/:id/:tokenid",
      routeName: "slideshow_slide",
      render: () => <Slideshow />
    },
    {
      route: "edit/:slideshowId/:slideId/:templateId?",
      routeName: "slideshow_slide_edit",
      render: () => <SlideEdit />
    },
  ]
})

const tube = (): ThisAppEntry => ({
  name: "Tube",
  startPriorityWeight: 3,
  icon: "video player",
  noLogin: false,
  route: "tube",
  routeName: "tube",
  title: "application::tube",
  hidden: false,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/tubeScreen.png",
  accessCheck: AccessHelpers.isInGroup("TUBE"),
  themeManipulation: (t: Theme) => ({
    ...t,
    mainArea: {
      ...t.mainArea,
      backgroundColor: t.applicationTheming.tubeTheme?.backgroundColor ?? t.mainArea.backgroundColor,
      color: t.applicationTheming.tubeTheme.color ?? t.mainArea.color,
    }
  }),
  right: {
    hidden: true,
  },
  contentRenderer: () => <TubeFeed />,
  views: [
    {
      route: "cat/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "tube_cat",
      render: () => <TubeFeed />,
    },
    {
      noLogin: true,
      renderDirectly: true,
      routeName: "tube_public",
      route: "video_public/:id",
      render: () => <TubeLinkedVideo publicAccess />
    },
    {
      renderDirectly: true,
      routeName: "tube_private",
      route: "video_private/:id",
      render: () => <TubeLinkedVideo />
    },
    {
      route: "video/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "tube_video_details",
      render: () => <TubeVideo />
    },
    {
      route: "upload",
      routeName: "tube_mass_upload",
      render: () => <TubeUpload />
    }
  ]
})

const podcast = (): ThisAppEntry => ({
  name: "Podcast",
  startPriorityWeight: 3,
  icon: "play circle",
  noLogin: false,
  route: "podcast",
  routeName: "podcast",
  title: "application::podcast",
  titleStyle: {
    textTransform: "capitalize",
  },
  hidden: false,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/podcastScreen.png",
  accessCheck: AccessHelpers.isInGroup("PODCAST"),
  themeManipulation: (t: Theme) => ({
    ...t,
    mainArea: {
      ...t.mainArea,
      backgroundColor: t.applicationTheming.podcastTheme?.backgroundColor ?? t.mainArea.backgroundColor,
      color: t.applicationTheming.podcastTheme.color ?? t.mainArea.color,
    }
  }),
  right: {
    hidden: true,
  },
  contentRenderer: () => <PodcastFeed />,
  views: [
    {
      route: "cat/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "podcast_cat",
      render: () => <PodcastFeed />,
    },
    {
      noLogin: true,
      renderDirectly: true,
      routeName: "podcast_public",
      route: "audio_public/:id",
      render: () => <PodcastLinkedVideo publicAccess />
    },
    {
      renderDirectly: true,
      routeName: "podcast_private",
      route: "audio_private/:id",
      render: () => <PodcastLinkedVideo />
    },
    {
      route: "audio/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "podcast_audio_details",
      render: () => <PodcastAudio />
    },
    {
      route: "upload",
      routeName: "podcast_mass_upload",
      render: () => <TubeUpload />
    }
  ]
})

const content = (): ThisAppEntry => ({
  startPriorityWeight: 4,
  name: "Content",
  icon: "content aggregation",
  noLogin: false,
  route: "content",
  routeName: "content",
  title: "application::content",
  hidden: false,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/smwScreen.png",
  accessCheck: AccessHelpers.isInGroup("LANDING PAGE SMART MOBILITY"),
  themeManipulation: (t: Theme) => ({
    ...t,
    mainArea: {
      ...t.mainArea,
      backgroundColor: t.applicationTheming.contentTheme?.backgroundColor ?? t.mainArea.backgroundColor,
      color: t.applicationTheming.contentTheme.color ?? t.mainArea.color,
    }
  }),
  right: {
    hidden: true,
  },
  contentRenderer: () => <ContentFeed />,
  views: [
    {
      route: "cat/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "content_cat",
      render: () => <ContentFeed />,
    },
    {
      route: "art/:id/:catid?/:catid1?/:catid2?/:catid3?/:catid4?/:catid5?",
      routeName: "content_art",
      render: () => <ContentFeedDetail />
    }
  ]
})

const scorm = (): ThisAppEntry => ({
  startPriorityWeight: 4,
  name: "Scorm",
  icon: "education",
  noLogin: false,
  route: "scorm",
  routeName: "scorm",
  title: "application::scorm",
  hidden: false,
  noPadding: true,
  right: {
    hidden: true,
  },
  left: {
    hidden: true,
  },
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/scormScreen.png",
  contentRenderer: () => <ScormOverview />,
  views: [
    {
      route: "legals",
      routeName: "scorm_legals",
      render: () => <ScormLegalNotes />,
    },
    {
      route: "player/:token/:id",
      routeName: "scorm_player",
      render: () => <ScormPlayer />,
    },
  ]
})

const assetManagement = (): ThisAppEntry => ({
  name: "Assets",
  icon: "playlist",
  noLogin: false,
  route: "assets",
  routeName: "assets",
  title: "application::asset management",
  hidden: false,
  left: {
    hidden: false,
  },
  right: {
    hidden: false,
  },
  contentRenderer: () => <AssetManagement />,
  views: [
    {
      route: "add",
      routeName: "assets_add",
      render: () => <AssetAdd />
    }
  ]
})

const pagStats = (): ThisAppEntry => ({
  name: "PAG Stats",
  icon: "admin",
  noLogin: false,
  route: "pag_stats",
  routeName: "pag_stats",
  title: "application::pag stats",
  hidden: false,
  order: 10000,
  accessCheck: AccessHelpers.isAdminSomewhere(),
  left: {
    hidden: true
  },
  right: {
    hidden: true,
  },
  contentRenderer: () => <PAGStats />,
});

const admin = (): ThisAppEntry => ({
  name: "Admin",
  icon: "admin",
  noLogin: false,
  route: "admin",
  routeName: "admin",
  title: "application::admin area",
  hidden: false,
  order: 10000,
  accessCheck: AccessHelpers.isAdminSomewhere(),
  right: {
    hidden: false
  },
  // right: {
  //   hidden: false,
  // },
  left: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("admin_tube_upload"))
          return s.adminState.leftSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "adminLeftSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("admin_tube_upload"))
          store.dispatch(Actions.adminSetLeftSidebar(!s.adminState.leftSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "adminLeftSidebar", (c) => !c);
      }
    }
  },
  contentRenderer: () => <AdminDemo />,
  views: [
    {
      route: "test/utcs",
      routeName: "testUCTS",
      render: () => <TestUCTS />,
    },
    {
      route: "tube/upload/:groupid/:id?",
      routeName: "admin_tube_upload",
      render: () => <TubeUpload />,
    },
    {
      route: "podcast/upload/:groupid/:id?",
      routeName: "admin_podcast_upload",
      render: () => <PodcastUpload />,
    },
    {
      route: "tube/group/:groupId?",
      routeName: "admin_tube_group",
      render: () => <TubeGroupPosts editRouteName="admin_tube_upload" />,
    },
    {
      route: "podcast/group/:groupId?",
      routeName: "admin_podcast_group",
      render: () => <PodcastGroupPosts editRouteName="admin_podcast_upload" />,
    },
    {
      route: "slides/group/:groupId?",
      routeName: "admin_slideshow_group",
      render: () => <SlideshowGroupPosts editRouteName="admin_slideshow_upload" />,
    },
    {
      route: "quiz/group/:groupId?",
      routeName: "admin_quiz_group",
      render: () => <QuizGroupPosts editRouteName="admin_quiz_and_question_edit" />,
    },
    {
      route: "quiz/edit/:groupid?/:id?",
      routeName: "admin_quiz_and_question_edit",
      render: () => <QuizAndQuestionEdit />
    },
    {
      route: "user/:id?",
      routeName: "admin_user",
      render: () => <UserManagement />,
      //accessCheck: () => false,
      accessCheck: AccessHelpers.isGlobalAdmin(),

    },
    {
      route: "user/ppn/check",
      routeName: "admin_user_ppn_check",
      render: () => <PPNUserCheck />,
      //accessCheck: () => false,
      accessCheck: AccessHelpers.isGlobalAdmin(),

    },
    {
      route: "translate",
      routeName: "admin_translateNS",
      render: () => <TranslateNameSpaces />,
      //accessCheck: () => false,
      accessCheck: AccessHelpers.isGlobalAdmin(),

    },
    {
      route: 'editCategoryLike/:kind/:groupId/:id/:path*',
      routeName: "admin_edit_category_like",
      render: () => <EditCategoryLike />,
    },
    {
      route: 'mails/:groupId?',
      routeName: "admin_group_mails",
      render: () => <GroupMails />,
    },
    {
      route: 'mail/edit/:groupId/:id?',
      routeName: "admin_edit_mail",
      render: () => <EditMail />,
    },
    {
      route: 'cattree/:id?',
      routeName: "admin_cat_tree",
      render: () => <CatTree />,
    },
    {
      route: "categories",
      routeName: "admin_categories",
      render: () => <ManageCategoryLike namespace="admin" homeRoute="admin" />,
    },
    {
      route: "locations",
      routeName: "admin_locations",
      render: () => <ManageCategoryLike namespace="admin" homeRoute="admin" kind={CategoryLikeTypes.Location} />,
    },
    {
      route: "ciaNews",
      routeName: "admin_ciaNews",
      render: () => <EditCiaNews />
    }
  ]
})

const best = (): ThisAppEntry => ({
  startPriorityWeight: 2,
  name: "BestPractice",
  icon: "bulb",
  route: "best",
  routeName: "best",
  title: "application::best practice",
  noLogin: false,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/priScreen.png",
  hidden: false,
  accessCheck: AccessHelpers.isInGroup("BEST PRACTICE"),
  contentRenderer: () => <BestPracticeMain />,
  right: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("best"))
          return s.bestpracticeState.rightSidebar ?? true;
        return getFrontEndSettingFromState<boolean>(s.user, "bestRightSidebar") ?? true;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("best"))
          store.dispatch(Actions.setBestRightSidebar(!s.bestpracticeState.rightSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "bestRightSidebar", (c) => !c);
      }
    }
  },
  views: [
    {
      route: "detail/:id",
      routeName: "best_detail",
      render: () => <BestPracticeDetail />,
    },
    {
      route: "add/:id?",
      routeName: "best_add",
      render: () => <BestPracticeAdd />
    },
  ]
})



const chat = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "Chat",
  icon: "chat",
  route: "chat",
  routeName: "chat",
  title: "application::chat",
  noLogin: false,
  hidden: false,
  accessCheck: AccessHelpers.isInGroup(["EXCITE", "COMMUNITY"]),
  contentRenderer: () => <ChatMain />,
  views: [
    {
      route: "in/:id",
      routeName: "chat_open",
      render: () => <Chat />
    },
    {
      route: "add",
      routeName: "chat_add",
      render: () => <ChatAdd />
    },
  ]
})

const inbox = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "Inbox",
  icon: "bell",
  title: "application::inbox",
  route: routeNames.inbox,
  routeName: "inbox",
  noLogin: false,
  hidden: true,
  contentRenderer: () => <Inbox />,
  right: {
    hidden: undefined,
  },
})

const user = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "User",
  icon: "user",
  title: "application::user profile",
  route: "user/:id?",
  routeName: "user",
  noLogin: false,
  hidden: true,
  contentRenderer: () => <User />,
})

const cms = (): ThisAppEntry => ({
  startPriorityWeight: 5,
  name: "CMS",
  icon: "user",
  title: "application::cms",
  route: routeNames.cms,
  routeName: "cms",
  noLogin: false,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/cmsScreen.png",
  hidden: false,
  order: 8000,
  right: {
    manageOpen: {
      get: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("cms_content") || c("cms_translation_batches") || c("cms_project"))
          return s.cmsState.rightSidebar ?? false;
        return getFrontEndSettingFromState<boolean>(s.user, "cmsRightSidebar") ?? false;
      },
      toggle: async () => {
        const s = store.getState();
        const c = createCheckWhereIAm(s.currentAppEntry, s.currentAppView);
        if (c("cms_content") || c("cms_translation_batches") || c("cms_project"))
          store.dispatch(Actions.setCMSRightSidebar(!s.cmsState.rightSidebar));
        else
          await setFrontEndSetting<boolean>(s.user, "cmsRightSidebar", (c) => !c);
      }
    }
  },
  actions: [
    Actions.setCMSSearchResultsFiles(undefined),
    Actions.setCMSSearchResultsProjects(undefined),
    Actions.setCMSContentSearchResultsFiles(undefined),
    Actions.setCMSContentSearchResultsProjects(undefined),
    // { type: "EXECUTE", value: () => updateCMSSearchOnRoute("", { allowFuzzy: true }, {}, undefined, undefined, "projects", true) },
  ],
  accessCheck: AccessHelpers.isInGroup("CMS"),
  contentRenderer: () => <CMS />,
  views: [
    {
      route: "project/:id?/:copy?",
      routeName: "cms_project",
      render: () => <CMSProject />
    },
    {
      route: routeNames.cmsRoutes.templates,
      routeName: "cms_templates",
      render: () => <CMSTemplates />
    },
    {
      route: routeNames.cmsRoutes.template,
      routeName: "cms_template",
      render: () => <CMSTemplate />
    },
    {
      route: routeNames.cmsRoutes.roles,
      routeName: "cms_roles",
      render: () => <CMSRoles />
    },

    {
      route: routeNames.cmsRoutes.role,
      routeName: "cms_create_edit_role",
      render: () => <CMSRole />,
    },
    {
      route: routeNames.cmsRoutes.directory,
      routeName: "cms_dir",
      render: () => <CMSDirectory />
    },
    {
      route: routeNames.cmsRoutes.translationBatches,
      routeName: "cms_translation_batches",
      render: () => <CMSTranslationBatches />
    },
    {
      route: routeNames.cmsRoutes.file,
      routeName: "cms_file",
      render: () => <CMSFile />
    },
    {
      route: routeNames.cmsRoutes.archive,
      routeName: "cms_archive",
      render: () => <CMSArchive />
    },
    {
      route: routeNames.cmsRoutes.archiveFile,
      routeName: "cms_archive_file",
      render: () => <CMSFile />
    },
    {
      route: routeNames.cmsRoutes.selectWorkflow,
      routeName: "cms_select_workflow",
      render: () => <GroupWorkflows />,
    },
    {
      route: routeNames.cmsRoutes.manageLocations,
      routeName: "cms_locations",
      render: () => <ManageCategoryLike kind={CategoryLikeTypes.Location} namespace="cms" homeRoute="cms" groupType={["CMS"]} />,
    },
    {
      route: routeNames.cmsRoutes.manageCategories,
      routeName: "cms_categories",
      render: () => <ManageCategoryLike namespace="cms" homeRoute="cms" groupType={["CMS"]} />,
    },
    {
      route: routeNames.cmsRoutes.createWorkflow,
      routeName: "cms_create_edit_workflow",
      render: () => <CMSWorkflow />,
    },
    {
      route: routeNames.cmscontent,
      routeName: "cms_content",
      render: () => <CMSContent />
    },
    // {
    //   name: "editCategory",
    //   route: routeNames.cmsRoutes.editCategory,
    //   routeName: "category",
    //   render: () => <CMSEditCategory />,
    // },
    // {
    //   name: "editLocation",
    //   route: routeNames.cmsRoutes.editLocation,
    //   routeName: "location",
    //   render: () => <CMSEditLocation />,
    // },
  ]
})

const cmscontent = (): ThisAppEntry => ({
  startPriorityWeight: 5,
  name: "CMS-Content",
  icon: "user",
  title: "application::cms",  // show cms-content view if user clicks on CMS button on top bar. "upgrade" due to story 9353, feature 9317
  route: routeNames.cms + '/' + routeNames.cmscontent,
  routeName: "cmscontent",
  noLogin: false,
  hidden: false,
  contentRenderer: () => <CMSContent />,
  accessCheck: AccessHelpers.isInGroup("CMS-OUTPUT"),
  right: {
    hidden: true,
  },
})


const wiki = (): ThisAppEntry => ({
  startPriorityWeight: 5,
  name: "Wiki",
  icon: "wiki",
  title: "application::wiki",
  route: routeNames.wiki,
  banner: "https://cdn.imaginarity.com/public/thepage/myhome/ppeScreen.png",
  routeName: "wiki",
  noLogin: false,
  hidden: false,
  accessCheck: AccessHelpers.isInGroup("WIKI"),
  themeManipulation: (t: Theme) => ({
    ...t,
    mainArea: {
      ...t.mainArea,
      backgroundColor: t.applicationTheming.wikiTheme?.backgroundColor ?? t.mainArea.backgroundColor,
      color: t.applicationTheming.wikiTheme.color ?? t.mainArea.color,
    }
  }),
  contentRenderer: () => <WikiHome />,
  views: [
    {
      route: "category/:id/:sid?",
      routeName: "wiki_category",
      render: () => <WikiCategory />
    },
    {
      route: "article/:id/:secId?/:colId?",
      // route: "art",
      routeName: "wiki_article",
      render: () => <WikiArticle />
    },
    {
      route: "new",
      // route: "art",
      routeName: "wiki_create_article",
      render: () => <WikiNewArticle />
    },
    {
      route: "edit/:id?",
      // route: "art",
      routeName: "wiki_edit_article",

      render: () => <WikiEditArticle />
    },
    {
      route: "addNews/:id?",
      routeName: "wiki_add_news",
      render: () => <WikiNewsAdd />
    },
    {
      route: "news/:id?",
      routeName: "wiki_news",
      render: () => <WikiHome />
    },
    {
      route: "recently",
      routeName: "wiki_recently",
      render: () => <WikiRecently />
    },
    {
      route: routeNames.wikiRoutes.faq,
      routeName: "wiki_faq",
      render: () => <WikiFaq />
    },
    {
      route: routeNames.wikiRoutes.translationSend,
      routeName: "wiki_translation_send",
      render: () => <WikiTranslationSend />
    },
    {
      route: routeNames.wikiRoutes.translationImport,
      routeName: "wiki_translation_import",
      render: () => <WikiTranslationImport />
    },
    {
      route: routeNames.wikiRoutes.copyWikiGroup,
      routeName: "wiki_copyWikiGroup",
      render: () => <WikiCopyGroup />
    }
  ]
})

const faq = (): ThisAppEntry => ({
  name: "FAQ",
  icon: "info plus",
  title: "application::faq",
  titleStyle: {
    textTransform: "uppercase",
    //fontWeight: 600,
  },
  route: "faq",
  routeName: "faq",
  noLogin: false,
  right: {
    hidden: true
  },
  hidden: false,
  order: 9000,
  contentRenderer: () => <Faq />,
})

const qanda = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "Q&A",
  icon: "info brain",
  title: "application::q&a",
  route: "qanda",
  routeName: "q&a",
  // banner: "https://cdn.imaginarity.com/public/images/PAG_X.svg",
  noLogin: false,
  hidden: false,
  contentRenderer: () => <QandAOverview />,
  views: [
    {
      route: "category/:id",
      routeName: "q&a_category",
      render: () => <QandACategory />
    },
    {
      route: "element/:id/:catId",
      routeName: "q&a_element",
      render: () => <QandAElement />
    }
  ]
})

const globalSearch = (): ThisAppEntry => ({
  name: "Global Search",
  icon: "search",
  title: "application::search",
  route: "search",
  routeName: "search",
  noLogin: false,
  hidden: true,
  contentRenderer: () => <GlobalSearchResults />
})

const thePage = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "Academy",
  icon: "info brain",
  route: "thepage",
  routeName: "thepage",
  // banner: "https://cdn.imaginarity.com/public/images/PAG_X.svg",
  title: "application::academy_board",
  noLogin: false,
  hidden: false,
  contentRenderer: () => <ThePage />,

})

const goToExternal = (): ThisAppEntry => ({
  startPriorityWeight: 1,
  name: "GoToExternal",
  icon: "chevron down",
  route: "goToExternal",
  routeName: "goToExternal",
  hidden: true,
  contentRenderer: () => <GoToExternal />
})


export const UpdateRequired = () => {
  const update = store.getState()?.requestUpdate;
  const { t } = useImgI18N("general");
  return (
    <div className="backdrop" style={{ display: update ? "block" : "none" }}>
      <button onClick={() => window.location.reload()}>
        {t("update is available. please close all tabs and reload.")}
      </button>
    </div>
  )
}

class App extends React.Component<AppProps, AppState> {
  public state: AppState = {
    colors: false,
    progress: 0,
    msg: "",
    loadingDone: x,
    showInfo: true
  };
  private startRoute: string | undefined = undefined;

  async componentDidMount() {
    // eslint-disable-next-line
    const getUrl = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/;
    const url = (getUrl.exec(document.location.href) ?? ["", "localhost"])[0].toLowerCase();
    //const url = "https://ppx-clone";

    const f = await ControllerHelper.singleCall({ url }, DefaultController.GetFeaturesV2, true);

    const entries: Array<ThisAppEntry | AppEntry> = [
      FallbackAppEntry,
      ResetPasswordAppEntry,
      SsoAuthenticationFailedEntry,
      HistoryViewAppEntry
    ]
    if (f) {
      if (f.modules.myHome)
        entries.push(myhome());
      if (f.modules.myHome)
        entries.push(dataPrivacy());
      if (f.modules.feed) {
        // entries.push(appDownload());
        entries.push(feed());
      }
      if (f.modules.academy)
        entries.push(academy());
      if (f.modules.cms) {
        entries.push(cms());
        entries.push(cmscontent());
      }
      if (f.modules.wiki)
        entries.push(wiki());
      if (f.modules.smirco)
        entries.push(smirco());
      if (f.modules.pcnaMobileAcademy)
        entries.push(pcnaMobileAcademy());
      if (f.modules.content)
        entries.push(content());
      if (f.modules.tube)
        entries.push(tube());
      // TOFIX!!
      if (f.modules.tube)
        entries.push(podcast());
      if (f.modules.quiz)
        entries.push(quiz());
      if (f.modules.chat)
        entries.push(chat());
      if (f.modules.faq)
        entries.push(faq());
      if (f.modules.user)
        entries.push(user());
      if (f.modules.playlist)
        entries.push(playlist());
      if (f.modules.globalSearch)
        entries.push(globalSearch());
      if (f.modules.welcome)
        entries.push(welcome());
      if (f.modules.support)
        entries.push(support());
      if (f.modules.greeting)
        entries.push(greeting());
      if (f.modules.termsOfUse)
        entries.push(termsOfUse());
      if (f.modules.joinView)
        entries.push(joinView());
      if (f.modules.admin)
        entries.push(admin());


      if (f.modules.scorm)
        entries.push(scorm());
      if (f.modules.goToExternal)
        entries.push(goToExternal());
      if (f.modules.demos)
        entries.push({
          ...demos(),
          noLogin: false,
        });
      if (f.modules.bestPartices)
        entries.push(best());
      if (f.modules.qAndA)
        entries.push(qanda());
      // if (f.modules.test)
      //   entries.push(test(this));
      if (f.modules.slideShow)
        entries.push(slide());
      // if (f.modules.activities)
      //   entries.push(activities(this));
      if (f.modules.thePage)
        entries.push(thePage());
      entries.push(openPost());
      if (f.modules.inbox)
        entries.push(inbox());

    }

    // put in new entries if needed
    if (window.location.hostname === 'localhost') {
      entries.push(...[
        peTest(),
        assetManagement(),
        pagStats()
      ])
    }

    //console.log("App.tsx::1909 => f", f);

    _.forEach(f?.applicationSettings, (settings, appName) => {
      const entry = _.find(entries, e => e.name?.toLocaleLowerCase() === appName.toLocaleLowerCase());
      if (entry) {
        entry.order = settings.tabOrder;
      }
    });

    await ImgI18N.getInstance().initialize();


    const pluginRegistry = PluginRegistry.getInstance();
    //pluginRegistry.addPluginToLoad("porscheicons", true);
    //pluginRegistry.addPluginToLoad("quiz", true);
    await pluginRegistry.loadPlugins({
      icons: imgIconDefs,
      store
    });
    setTimeout(() => this.setState({ loadingDone: true }), 0);

    store.dispatch(PluginActions.batchActions([
      PluginActions.addAppEntries(entries as AppEntry[]),
      PluginActions.addModules(a1),
      PluginActions.setPlatformFeatures(f),
    ]))

    // store.dispatch(PluginActions.addAppEntries(entries));
    // store.dispatch(PluginActions.addModules(a1));
    // store.dispatch(PluginActions.setPlatformFeatures(f));
    //this.startRoute = "/graph";
    if (MobileHelper.getInstance().mobileClient())
      this.startRoute = "/community";

  }

  public render() {

    //console.log("Mobile => ", MobileHelper.getInstance().mobileClient());

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    register({
      onSuccess: registration => {
        console.log("registered app for offline use. details:", registration)
      },
      onUpdate: registration => {
        //const waitingServiceWorker = registration.waiting

        store.dispatch(Actions.requestUpdate(true));
        console.log("Update called to check updates");

        // if (waitingServiceWorker) {
        //   waitingServiceWorker.addEventListener("statechange", (event: any) => {
        //     if (event.target.state === "activated") {
        //       store.dispatch(Actions.requestUpdate(true))
        //     }
        //   })
        // }
      },
    })



    // console.log(applicationTheme);
    const isIE = (getBrowser().name === "ie");
    //console.log('this.startRoute => ', this.startRoute);
    //console.log('maxApp.route => ', maxApp?.route);
    return (
      <>
        <UpdateRequired />

        <Provider store={store}>
          <HandleAppFocus />
          <ImgThemeProvider theme={applicationTheme}>
            <Suspense fallback={null}>
              {this.state.loadingDone ?
                <MasterLayoutRouter startRoute={this.startRoute} previewRender={() => <AppStart progress={this.state.progress} msg={this.state.msg} />}>
                  <Div100vh>
                    <MasterLayout
                      //leftModules={["ContentInteraction", "SystemInfo"]}
                      //rightModules={["SearchAndFilter", "ContentInfo", "ContentContext", "UserInteraction"]}
                      leftHeader={<Search />}
                      rightHeader={<UserStatus />}
                    />
                  </Div100vh>
                </MasterLayoutRouter>
                :
                <AppStart progress={this.state.progress} msg={this.state.msg} />
              }

              <GlobalStyle />
              {
                isIE && this.state.showInfo &&
                <IEWarning onClick={this.hideInfo}>
                  We suggest to use a modern Browser - IE 11 is outdated and this site will look better in modern browsers.
                </IEWarning>
              }
              <PageStateConnection />
            </Suspense>
          </ImgThemeProvider>
        </Provider >
      </>
    );
  }

  public hideInfo = () => {
    this.setState({ showInfo: false });
  }
  public toggleColors = () => {
    this.setState({ colors: !this.state.colors });
  }
}

export default App;
